import {
    Timeline,
    TimelineConnector, TimelineContent, TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    timelineOppositeContentClasses,
    TimelineSeparator
} from "@mui/lab";
import {List, ListItem, Stack, Typography} from "@mui/material";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import TerrainOutlinedIcon from "@mui/icons-material/TerrainOutlined";
import React from "react";
import ConfigTour from "../Config/ConfigTour";

interface Props {
    program: any
    actionButton?: any
}

const ProgramTimeline = (props: Props) => {
    const {program, actionButton} = props

    const ContentGenList = (items: any) => {
        return <List
            sx={{
                listStyleType: "disc",
                marginLeft: "12px"
            }}
        >
            {items.map((i: any, index: number) => {
                return <ListItem
                    key={index}
                    sx={{
                        display: "list-item",
                        p: 0,
                    }}
                >
                    <Typography variant="body1" gutterBottom ml={1}>
                        {i.item}
                    </Typography>
                </ListItem>
            })}
        </List>
    }

    const TimeLineTypeIcon = (programType: string) => {
        const options = ConfigTour.ProgramTypeOptions.find(
            (o) => o.value === programType
        )
        const background = options && options.color ? options.color : ""
        const icon = options && options.icon ? options.icon : ""
        return <TimelineDot sx={{background: background, color: "white"}}>
            {icon}
        </TimelineDot>
    }

    return <Timeline
        sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.13,
            },
            padding: 0
        }}
    >
        {program.map((p: any, index: number) => {
            return <TimelineItem key={index}>
                <TimelineOppositeContent sx={{paddingLeft: 0}}>
                    <Typography variant='h6'>
                        {p.prefix}
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    {TimeLineTypeIcon(p.type)}
                    {index + 1 < program.length ? <TimelineConnector/> : ""}
                </TimelineSeparator>
                <TimelineContent>
                    {p.headline ?
                        <Stack direction="row"
                               gap={1}
                               useFlexGap
                               justifyContent="flex-start"
                               alignItems="center"
                        >
                            <Typography variant='h6' sx={{flex: 1}}>
                                {p.headline}
                            </Typography>
                            {actionButton ? actionButton(index) : ""}
                        </Stack> : ""}
                    {p.text && p.text.length && typeof p.text[0].name !== "undefined" ?
                        <Stack direction="row"
                               gap={1}
                               useFlexGap
                               justifyContent="flex-start"
                               alignItems="center"
                        >
                            <Typography variant='h6' sx={{flex: 1}}>
                                {p.text[0].name}
                            </Typography>
                            {actionButton ? actionButton(index) : ""}
                        </Stack> : ""}
                    {p.description ?
                        <Typography variant='body1' textAlign={"justify"}>
                            {p.description}
                        </Typography> : ""}
                    {p.activity || p.accommodation ?
                        <Stack direction="row" gap={1} alignItems="center">
                            {p.accommodation ?
                                <>
                                    <HotelOutlinedIcon fontSize="small"/>
                                    <Typography variant='body2'>
                                        {p.accommodation}
                                    </Typography>
                                </> : ""}
                            {p.activity ?
                                <>
                                    <TerrainOutlinedIcon fontSize="small"/>
                                    <Typography variant='body2'>
                                        {p.activity}
                                    </Typography>
                                </> : ""}
                        </Stack> : ""
                    }
                    {p.text && p.text.length && typeof p.text[0].text !== "undefined" ?
                        <Typography variant="body1" sx={{marginTop: 0}}>
                            {p.text[0].text}
                        </Typography>
                        : ""}
                    {p.listItem && p.listItem.length > 0 ? ContentGenList(p.listItem) : ""}
                </TimelineContent>
            </TimelineItem>
        })}
    </Timeline>

}

export default ProgramTimeline