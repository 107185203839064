import React, {useEffect} from "react"
import Api from "../../../Api/Api";
import {useNavigate} from "react-router-dom";
import {Box, Stack, Typography, Button} from "@mui/material";
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import ResizeBox from "../../../Components/ResizeBox";


const TextModules = () => {

    interface TextModule {
        id: number | null
        name: string | null
        type: string | null
        text: string | null
    }

    const navigate = useNavigate();
    const [textModules, setTextModules] = React.useState<TextModule[]>([]);
    const [changed, setChanged] = React.useState(false)

    useEffect(() => {
        Api.Request('/tour/text').then((data: any) => {
            if (data) {
                setTextModules(data.payload);
            }
            setChanged(false)
        })
    }, [changed]);

    const onRowClick = (id: number) => {
        navigate("/mgmt/textModules/edit/" + id, {replace: true})
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.2,
            headerClassName: 'tableHeader',
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerClassName: 'tableHeader',
        },
        {
            field: 'text',
            headerName: 'Text',
            flex: 2,
            headerClassName: 'tableHeader',
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.5,
            headerClassName: 'tableHeader',
        },
    ];

    return <ResizeBox.Box>
        <Box sx={{width: "100%"}}>
            <Stack direction="row" alignItems="center" gap={1}>
                <TextSnippetOutlinedIcon fontSize='medium'/>
                <Typography variant='h5'>Text Modules</Typography>
            </Stack>
            <div className='pd1'/>
            <Button size="small" onClick={() => navigate("/mgmt/textModules/edit", {replace: true})}>
                Add a Text Module
            </Button>
            <DataGrid
                sx={{height: ResizeBox.Height}}
                autoPageSize
                rows={textModules}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 20, 30]}
                onRowSelectionModelChange={(i: any) => {
                    onRowClick(i)
                }}
                rowHeight={39}
                columnHeaderHeight={39}
            />
        </Box>
    </ResizeBox.Box>
}

export default TextModules