import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import Api from "./Api/Api";
import ViewTourGroup from "./View/Tour/ViewTourGroup";
import ViewTour from "./View/Tour/ViewTour";
import ScrollToTop from "./Components/ScrollToTop";

const AppRender = () => {
    const routeDefinition = {
        pointTo: "",
        route: "",
        id: 0
    }
    const params = useParams()
    const {"*": splat} = params;
    const [routes, setRoutes] = useState([routeDefinition])
    const navigate = useNavigate()

    useEffect(() => {
        Api.RequestPublic('/route').then((d: any) => {
            setRoutes(d.payload)
        })
    }, [])

    const route = () => {
        return typeof routes !== "undefined" ? routes.find((s) => s.route === splat) : routeDefinition
    }

    const content = () => {
        const r = route()

        if (typeof r !== "undefined") {
            switch (r.pointTo) {
                case "tour_group": {
                    return <>
                        <ScrollToTop />
                        <AppHeader/>
                        <ViewTourGroup id={r.id}/>
                        <AppFooter/>
                    </>
                }
                case "tour": {
                    return <>
                        <ScrollToTop />
                        <AppHeader/>
                        <ViewTour id={r.id}/>
                        <AppFooter/>
                    </>
                }
                default : {
                    navigate("/")
                }
            }
        }
    }

    return <>
        {content()}
    </>

}

export default AppRender