import React, {useEffect, useState} from "react"
import PhotoApi, {PhotoSetType} from "../../../Api/PhotoApi";
import {DataGrid, GridColDef, GridRowSelectionModel} from "@mui/x-data-grid";
import ResponsiveImage from "../../../Components/ResponsiveImage";
import {Button, Dialog, DialogContent, DialogTitle, IconButton, Stack} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ResizeBox from "../../../Components/ResizeBox";

interface Props {
    open: any
    show: any
    onSelect: any
}

const DialogSelectPhotoSet = (props: Props) => {
    const {open, show, onSelect} = props
    const [photoSets, setPhotoSets] = useState<PhotoSetType[]>(PhotoApi.DefaultPhotoSet)
    useEffect(() => {
        PhotoApi.GetPhotoSets().then((data: any) => {
            if (data) {
                setPhotoSets(data.payload.map((p: any) => {
                    return {...p, count: p.photo.length}
                }));
            }
        })
    }, []);

    const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([])
    const onRowClick = (id: any) => {
        setRowSelection(id)
    }

    const onAdd = () => {

        const newSet = photoSets.find((item) => item.id === rowSelection[0])
        console.log("rowSelection: " + JSON.stringify(newSet))
        onSelect(newSet)
        setRowSelection([])
        show(false)
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "photo",
            headerName: "Image",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return <ResponsiveImage src={params.value[0].thumb} sx={{height: "50px"}}/>
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5,
            headerClassName: 'tableHeader',
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            headerClassName: 'tableHeader',
        },
        {
            field: 'count',
            headerName: '#Photos',
            headerClassName: 'tableHeader',
        },
    ]

    return <Dialog
        open={open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => show(false)}>
        <DialogTitle sx={{padding: "10px 12px"}}>
            Select Photo Set:
            <IconButton
                aria-label="close"
                onClick={() => show(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}>
                <CloseRoundedIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{padding: "10px 12px"}}>
            <DataGrid
                sx={{
                    height: ResizeBox.Height,
                    ".MuiDataGrid-columnHeaderTitleContainer": {
                        backgroundColor: "aliceblue",
                    },
                }}
                autoPageSize
                rows={photoSets}
                columns={columns}
                checkboxSelection
                disableMultipleRowSelection
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 20, 30]}
                onRowSelectionModelChange={(i: any) => {
                    onRowClick(i)
                }}
                rowSelectionModel={rowSelection}
                rowHeight={39}
                columnHeaderHeight={39}
            />
            <Stack direction="row-reverse">
                <Button onClick={() => onAdd()} disabled={rowSelection.length === 0}>
                    Add
                </Button>
                <Button onClick={() => show(false)}>
                    Cancel
                </Button>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default DialogSelectPhotoSet