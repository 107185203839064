import React, {ReactNode, useState} from "react"
import useDimensions from "react-cool-dimensions";

interface Props {
    children: ReactNode
    offset?: number
}

let boxHeight: number;
const defaultOffset: number = 280
const defaultHeight: string = "77vh"

const ResizeBox: any = {

    Box: (props: Props) => {
        const [targetHeight, setTargetHeight] = useState(0)
        const {observe} = useDimensions({
            onResize: ({height}) => {
                if (height !== targetHeight) {
                    setTargetHeight(height)
                    boxHeight = height
                }
            },
        });

        return <div ref={observe} style={{height: targetHeight}}>
            {props.children}
        </div>
    },

    Height: (props: Props) => {
        const offset = props.offset ? props.offset : defaultOffset
        return boxHeight ? String(boxHeight - offset) + "px" : defaultHeight
    }

}

export default ResizeBox