import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import DropZone from "../../../../Components/DropZone";
import {useImmer} from "use-immer";
import Transform from "../../../../Common/Transform";
import TourApi from "../../../../Api/TourApi";
import ResizeBox from "../../../../Components/ResizeBox";
import {FormControlLabel, Stack, TextField, Typography, Checkbox, Button, Divider} from "@mui/material";
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';

const GroupEdit = () => {
    const navigate = useNavigate();
    const params = useParams()

    interface TourGroupDefinition {
        id: number,
        route: {
            slug: string,
            route: string,
            pointTo: string,
            active: boolean
        },
        name: string,
        subtitle: string,
        description: string,
        active: boolean,
        orderID: number,
        headerPhoto: {
            id: number
            link: string
        },
        cardPhoto: {
            id: number
            link: string
        }
    }

    const [tourGroup, setTourGroup] = useImmer<TourGroupDefinition>(TourApi.TourGroupDefinition)
    const [load, setLoad] = useState(true)
    const [slugyfied, setSlugyfied] = useState(false)

    if (params.id && load) {
        setLoad(false)
        TourApi.GetTourGroupDetail(params.id).then((data: any) => {
            const payload = data.payload
            setTourGroup(payload)
        })
    }

    const onSetActive = (checked: boolean | undefined) => (
        setTourGroup((draft) => {
            draft.active = checked ? checked : false
        })
    )

    const onChange = (e: any) => {
        e.preventDefault()
        const value = e.target.value ? e.target.value : ""
        const key = e.target.name ? e.target.name : ""
        setTourGroup((draft) => {
            switch (key) {
                case "name": {
                    draft.name = value
                    if (tourGroup.route.slug.length !== value && !slugyfied) {
                        draft.route.slug = Transform.Slugify(value)
                    }
                    break
                }
                case "slug": {
                    draft.route.slug = value
                    setSlugyfied(true)
                    break
                }
                case "subtitle": {
                    draft.subtitle = value
                    break
                }
                case "description": {
                    draft.description = value
                    break
                }
                case "orderID": {
                    draft.orderID = Number(value)
                    break
                }
            }
        })
    }

    const onChangeHeaderFile = (files: any) => {
        setTourGroup((draft) => {
            draft.headerPhoto = files[0]
        })
    }

    const onChangeCardFile = (files: any) => {
        setTourGroup((draft) => {
            draft.cardPhoto = files[0]
        })
    }


    const onSave = () => {
        TourApi.PersistGroup(tourGroup).then(() => {
            setTourGroup(TourApi.TourGroupDefinition)
            navigate("/mgmt/tour/group")
        }).catch((error: string) => {
            console.log("TourGroupEdit, onSave: " + error)
        })
    }

    const canSave = () => {
        return !!(tourGroup.name &&
            tourGroup.subtitle &&
            tourGroup.headerPhoto &&
            tourGroup.cardPhoto)
    }

    return <ResizeBox.Box>
        <Stack direction="row" alignItems="center" gap={1}>
            <HolidayVillageOutlinedIcon fontSize='medium'/>
            <Typography
                variant="h5">{tourGroup.name ? `Edit TourOverview Group "${tourGroup.name}"` : "Create TourOverview Group"}</Typography>
        </Stack>
        <div className='pd1'/>
        <div style={{minHeight: ResizeBox.Height({offset: 280})}}>
            <Stack direction="row" alignItems="center">
                <TextField
                    autoFocus
                    required
                    sx={{
                        m: 1,
                        width: '40ch'
                    }}
                    value={tourGroup.name ? tourGroup.name : ""}
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    helperText="Eindeutiger Name für die TourOverview Gruppe"
                    type="text"
                    variant="standard"
                    onChange={onChange}
                />
                <TextField
                    autoFocus
                    required
                    sx={{m: 1}}
                    fullWidth
                    value={tourGroup.subtitle ? tourGroup.subtitle : ""}
                    margin="dense"
                    id="subtitle"
                    name="subtitle"
                    helperText="Kurze Beschreibung für Startseite"
                    label="Subtitle"
                    type="text"
                    variant="standard"
                    onChange={onChange}
                />
            </Stack>
            <Stack direction="row" alignItems="center">
                <TextField
                    autoFocus
                    required
                    sx={{m: 1}}
                    fullWidth
                    value={tourGroup.route.slug ? tourGroup.route.slug : tourGroup.name ? Transform.Slugify(tourGroup.name) : ""}
                    margin="dense"
                    id="slug"
                    name="slug"
                    label="Slug"
                    helperText="URL Kürzel, wird dann an silvertip.at/ angehängt. z.B.: aus Peru wird www.silvertip.at/peru"
                    type="text"
                    variant="standard"
                    onChange={onChange}
                />
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
                <TextField
                    autoFocus
                    required
                    sx={{
                        m: 1,
                        width: '10ch'
                    }}
                    value={tourGroup.orderID ? tourGroup.orderID : 0}
                    margin="dense"
                    id="orderID"
                    name="orderID"
                    label="Reinfolge"
                    type="number"
                    variant="standard"
                    onChange={onChange}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            checked={tourGroup.active}
                            onChange={(_, d) => onSetActive(d)}
                        />
                    }
                    sx={{mt: '14px'}}
                    label="Active"
                />
            </Stack>
            <Stack direction="row" alignItems="center">
                <TextField
                    id="description"
                    label="Beschreibung"
                    variant="filled"
                    multiline
                    value={tourGroup.description ? tourGroup.description : ""}
                    placeholder='Beschreibung'
                    name="description"
                    sx={{m: 1}}
                    fullWidth
                    rows={5}
                    onChange={onChange}
                />
            </Stack>
            <Stack direction="row" alignItems="center" gap={1} sx={{m: 1}}>
                <Stack direction="column" useFlexGap flexWrap="wrap">
                    <Typography variant="subtitle2">Header Image</Typography>
                    <Typography variant="body2">Format should be 16:4 like 1360px x 340px</Typography>
                    <DropZone maxFiles={1} files={[tourGroup.headerPhoto]} onChange={onChangeHeaderFile} format="header"
                              variant="header"/>
                </Stack>
                <Stack direction="column" useFlexGap flexWrap="wrap">
                    <Typography variant="subtitle2">Card Image</Typography>
                    <Typography variant="body2">Format should be 1:1 like 290px x 290px</Typography>
                    <DropZone maxFiles={1} files={[tourGroup.cardPhoto]} onChange={onChangeCardFile} variant="card"/>
                </Stack>
            </Stack>
            <Divider sx={{mt: 3}}/>
        </div>
        <Stack direction="row-reverse" sx={{paddingBottom: 4}}>
            <Button onClick={onSave} disabled={!canSave()}>
                {tourGroup.id ? "Update" : "Create"}
            </Button>
            <Button
                onClick={() => navigate("/mgmt/tour/group")}>
                Cancel
            </Button>
            <Button
                onClick={() => {
                    console.log("remove")
                }} color="error">
                Delete
            </Button>
        </Stack>
    </ResizeBox.Box>
}

export default GroupEdit