import React, {useState} from "react"
import ResizeBox from "../../../Components/ResizeBox";
import {useTranslation} from "react-i18next";
import MgmtPageTitle from "../Components/MgmtPageTitle";
import DropZone from "../../../Components/DropZone";
import MgmtPageActions from "../Components/MgmtPageActions";
import {useNavigate} from "react-router-dom";
import {Typography} from "@mui/material";

const PhotoAdd = () => {
    const {t} = useTranslation();
    const [files, setFiles] = useState([])
    const [variant, setVariant] = useState("")
    const navigate = useNavigate();

    const changeVariant = (variant: string) => {
        setFiles([])
        setVariant(variant)
    }

    const actions = [
        {label: "photo.addImpression", onClick: () => changeVariant("impression")},
        {label: "photo.addHeader", onClick: () => changeVariant("header")},
        {label: "photo.addCard", onClick: () => changeVariant("card")},
        {label: "generics.close", onClick: () => navigate("/mgmt/photo"), icon: "close"},
    ]

    const onChange = (files: any) => {
        setFiles(files)
    }

    const getFormat = (variant: string) => {
        if (variant === "header") {
            return "header"
        }
        return undefined
    }

    return <ResizeBox.Box>
        <MgmtPageTitle label={"photo.add"} icon={"add_photo_alternate"}/>
        <div style={{minHeight: ResizeBox.Height({offset: 280}), marginTop: "0.5em"}}>
            <MgmtPageActions actions={actions}/>
            {variant ? <>
                    <Typography variant="h6">{t("photo." + variant)}</Typography>
                    <DropZone
                        maxFiles={24}
                        files={files}
                        onChange={onChange}
                        maxHeight={220}
                        format={getFormat(variant)}
                        variant={variant}/> </>
                : ""
            }
        </div>
    </ResizeBox.Box>
}

export default PhotoAdd