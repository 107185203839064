import React from "react"
import {Paper, Stack, Typography} from "@mui/material";
import Utils from "../../Common/Utils";

interface Props {
    info: any
    infoText: any
}

const TourInfo = (props: Props) => {
    const {info, infoText} = props
    let lines: any = []

    if (info && info.length > 0) lines.push(...info)
    if (infoText && infoText.length > 0) lines.push(...infoText)

    // const sorted = lines.sort((a: any, b: any) => {
    //     if (!a.name || !b.name) return 0
    //     return a.name.localeCompare(b.name)
    // })

    const InfoItems = () => {
        const nw = Utils.calcMaxWidthCharacters(lines,"name")
        return <Stack mt={2}>
            {lines.map((item: any, index: number) => {
                return <Stack direction={"row"} key={index} mt={0.5} justifyItems={"center"} textAlign={"left"} alignItems={"top"} gap={1}>
                    <Typography variant={"body1"} sx={{fontWeight: 600, mr: 1, width: nw}}>
                        {item.name}:
                    </Typography>
                    <Typography variant={"body1"} flex={1}>{item.text}</Typography>
                </Stack>
            })}
        </Stack>
    }

    return info || infoText ?
        <Paper
            elevation={0}
            sx={{
                background: "rgb(69,147,198,0.05)",
                p: 2,
                flex: 0.4,
                height: "100%"
            }}
        >
            <Typography variant='h6'>
                Allgemeine Infos
            </Typography>
            <InfoItems/>
        </Paper>
        :
        <></>
}

export default TourInfo