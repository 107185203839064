import React, {useCallback, useEffect, useState} from "react"
import {useImmer} from "use-immer";
import API from "../../../../Api/Api";
import {
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, IconButton, InputLabel,
    MenuItem, Select,
    Stack, TextField,
    Typography
} from "@mui/material";


import {TFunction} from "i18next";
import {Delete, Edit} from "@mui/icons-material";
import MgmtPageActions from "../../Components/MgmtPageActions.tsx";
import utils from "../../../../Common/Utils.tsx";
import MgmtPageSaveClose from "../../Components/MgmtPageSaveClose.tsx";
import MgmtPageTitle from "../../Components/MgmtPageTitle.tsx";

interface Props {
    t: TFunction
    onChange: any
    onChangeText: any
    text: any
    filter: string
    values: any
    title?: string
}

const TourEditText = (props: Props) => {

    const t = props.t

    interface TextModule {
        id: number | null
        type: string
        name: string
        text: string
        actionID: string | null
    }

    interface Item {
        id: number | null
        item: string
        order: number
        toDelete: boolean
        actionID: string | null
    }

    interface Options {
        key: number
        value: number
        text: string
        name: string
    }

    const [items, setItems] = useImmer<Item[]>([])
    const [text, setText] = useState<TextModule[]>([])
    const [options, setOptions] = useState<Options[]>([])
    const [textModules, setTextModules] = useState<TextModule[]>([])
    const [loading, setLoading] = useState(true)
    const [showDialogFreeText, setShowDialogFreeText] = useState(false)
    const [editItem, setEditItem] = useState('')
    const [undoItem, setUndoItem] = useState('')
    const [showDialogTextModule, setShowDialogTextModule] = useState(false)

    const updateOptions = useCallback((modules: any, values: TextModule[]) => {
        const newOptions: Options[] = []
        modules.forEach((d: any) => {
            if (values && !values.find((t: any) => {
                return d.id === t.id
            })) {
                newOptions.push({
                    key: d.id,
                    value: d.id,
                    text: d.text,
                    name: d.name,
                })
            } else if (!values) {
                newOptions.push({
                    key: d.id,
                    value: d.id,
                    text: d.text,
                    name: d.name,
                })
            }
        })
        if (newOptions.length > 0) {
            setOptions(newOptions)
        }
    }, [])

    // Load initial values
    useEffect(() => {
        if (loading) {
            if (props.values) {
                setItems(props.values.map((d: Item) => {
                    return {...d, actionID: utils.makeId()}
                }))
            }
            if (props.text) {
                setText(props.text.map((d: TextModule) => {
                    return {...d, actionID: utils.makeId()}
                }))
            }
            API.Request('/tour/text').then((d: any) => {
                const modules = d.payload.filter((d: any) => d.type === props.filter || d.type === "universal")
                updateOptions(modules, props.text)
                setTextModules(modules)
            })
            setLoading(false)
        }
    }, [loading, props, setItems, updateOptions])

    const onChangeItem = (text: string, actionID: string) => {
        const item = items.find((d: any) => d.actionID === actionID)
        if (item === undefined) return
        const index = items.findIndex((d) => d.actionID === actionID)
        setItems((draft) => {
            draft.splice(index, 1)
            draft.push({...item, item: text})
        })
    }

    const saveNewItem = (value: Item) => {
        if (!value) return

        const multi = value.item.split(/\r?\n|\r|\n/g);
        const count = items ? items.length : 0
        const newItems = [...items]
        if (multi.length > 1) {
            newItems.splice(newItems.findIndex((i) => i.actionID === value.actionID), 1)
            multi.forEach((i: any, index: number) => {
                    newItems.push({
                        item: i,
                        order: count + index,
                        toDelete: false,
                        id: null,
                        actionID: utils.makeId()
                    })
                }
            )
            setItems(newItems)
        }

        setShowDialogFreeText(false)
        props.onChange(newItems)
    }

    const onSelect = (value: any) => {
        if (value === -1) return

        const toAdd = textModules.find((m) => {
            return m.id === value
        })

        if (toAdd) {
            setItems((draft) => {
                draft.push({
                    id: null,
                    order: items.length + 1,
                    toDelete: false,
                    item: toAdd.text,
                    actionID: utils.makeId()
                })
                props.onChange(draft)
            })
        }

        const newOptions = options.filter((d) => {
            return d.value !== value
        })
        setOptions(newOptions)
        setShowDialogTextModule(false)
    }

    // onDeleteModule delete text module
    const onDeleteModule = (id: string) => {
        const newText = text.filter((d) => {
            return d.actionID !== id
        })
        setText(newText)
        props.onChangeText(props.filter, newText)
        updateOptions(textModules, newText)
    }

    const onDeleteItem = (id: string) => {
        if (!id) return
        // remove new unsaved items
        const rmNew = items.filter((item) => !(item.actionID === id && item.id === null))
        // set toDelete
        const newItems = rmNew.map((item) => {
            if (item.actionID === id) {
                return {...item, toDelete: true}
            } else {
                return {...item}
            }
        })
        setEditItem('')
        setShowDialogTextModule(false)
        props.onChange(newItems)
        setLoading(true)
    }

    const filterToDelete = (items: any) => {
        if (items) {
            return items.filter((i: any) => !i.toDelete)
        } else {
            return []
        }
    }

    const createNewItem = () => {
        const item = {actionID: utils.makeId(), item: "", id: null, order: 0, toDelete: false}
        setItems((draft) => {
            draft.push(item)
        })
        setEditItem(item.actionID)
        setShowDialogFreeText(true)
    }

    const undoChange = () => {
        if (!undoItem) {
            onDeleteItem(editItem)
        } else {
            onChangeItem(undoItem, editItem)
            setUndoItem('')
        }
        setShowDialogFreeText(false)
    }

    const DialogFreeText = () => {
        const item = editItem ? items.find((i: any) => i.actionID === editItem) : undefined
        if (item === undefined) return
        const isNew = !item.item && !undoItem
        return <Dialog open={showDialogFreeText} onClose={() => setShowDialogFreeText(false)} fullWidth maxWidth={"lg"}>
            <DialogTitle>
                <MgmtPageTitle label={isNew ? t("mgmtTourEdit.addNewItem") : t("mgmtTourEdit.updateItem")} icon={'list'}
                               disableBorder={true}/>
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{flex: 1}}
                    multiline
                    value={item.item}
                    onChange={(e) => {
                        onChangeItem(e.target.value, editItem)
                    }}
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="item"
                    name="item"
                    label={isNew ? t("mgmtTourEdit.newItem") : t("mgmtTourEdit.updateItem")}
                    type="text"
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <MgmtPageSaveClose
                    disabled={!Boolean(item.item) || Boolean(undoItem === item.item)}
                    onSave={() => saveNewItem(item)}
                    updateSelector={!isNew}
                    discard={() => undoChange()}/>
            </DialogActions>
        </Dialog>
    }

    const DialogTextModule = () => {
        return <Dialog open={showDialogTextModule} onClose={() => setShowDialogTextModule(false)}
                       fullWidth maxWidth={"lg"}>
            <DialogTitle>
                {t("mgmtTourEdit.addTextModule")}
            </DialogTitle>
            <DialogContent>
                <FormControl variant="standard" sx={{m: 1, width: "100%"}}>
                    <InputLabel> {t("mgmtTourEdit.textModule")}</InputLabel>
                    <Select
                        id="textModule"
                        name="textModule"
                        variant="standard"
                        value={""}
                        margin="dense"
                        onChange={(event) => onSelect(event.target.value)}
                        label="Type"
                        SelectDisplayProps={{
                            style: {display: 'flex', alignItems: 'center'},
                        }}>
                        {options.map((option) => {
                            return <MenuItem value={option.value} key={option.key}>
                                <Typography variant="subtitle2">Name:</Typography>
                                <Typography variant="body1" ml={1}> {option.name}</Typography>
                                <Typography variant="subtitle2" ml={2}>Text:</Typography>
                                <Typography variant="body1" ml={1}> {option.text}</Typography>
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </DialogContent>
        </Dialog>
    }

    const RenderList = ({items}: any) => {
        // TODO: Change to order value as soon as available
        const sorted = items.sort((a: any, b: any) => {
            if (!a.item || !b.item) return 0
            return a.item.localeCompare(b.item)
        })
        return sorted.map((item: any) => {
            return RenderItem(item.item, item.actionID)
        })
    }

    const RenderModule = ({items}: any) => {
        return items.map((item: any) => {
            return RenderItem(item.text, item.actionID, true, true)
        })
    }

    // RenderItem returns item line with bullet and action buttons using the id as identifier for actions
    // If disableEdit is true, the edit action button will be disabled
    const RenderItem = (item: string, id: string, disableEdit?: boolean, isModule?: boolean) => {
        return <Stack direction={"row"} key={id} style={{borderBottom: "1px dotted lightgray", marginTop: "1em"}}>
            <Typography variant="body1"
                        style={{
                            position: "relative",
                            top: "8px",
                            left: "-5px",
                            width: "6px",
                            height: "6px",
                            borderRadius: "50%",
                            border: "2px solid black"
                        }}/>
            <Typography variant="body1" ml={1} sx={{display: "contents"}}>{item}</Typography>
            <div style={{marginLeft: "auto", marginRight: "0px"}}>
                <IconButton color="primary" aria-label="edit list item" size="small" disabled={disableEdit}
                            onClick={() => {
                                setUndoItem(item)
                                setEditItem(id)
                                setShowDialogFreeText(true)
                            }}>
                    <Edit fontSize="small"/>
                </IconButton>
                <IconButton color="warning" aria-label="delete list item" size="small"
                            onClick={() => {
                                isModule ? onDeleteModule(id) : onDeleteItem(id)
                            }}>
                    <Delete fontSize="small"/>
                </IconButton>
            </div>
        </Stack>
    }

    const RenderTitle = ({title}: any) => {
        return <Typography variant="h6" fontSize="1em"
                           style={{marginLeft: "-7px", borderBottom: "lightgray solid 1px"}}>
            {title}
        </Typography>
    }
    const actions = [
        {label: "mgmtTourEdit.addTextModule", onClick: () => setShowDialogTextModule(true)},
        {label: "mgmtTourEdit.addText", onClick: () => createNewItem()},
    ]

    return <>
        {props.title ? <RenderTitle title={props.title}/> : ''}
        <MgmtPageActions actions={actions} style={{marginLeft: "-11px"}}/>
        <RenderList items={filterToDelete(items)}/>
        <RenderModule items={filterToDelete(text)}/>
        <DialogTextModule/>
        {DialogFreeText()}
    </>
}

export default TourEditText