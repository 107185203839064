import * as React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react'
import { LinkProps, useHref, useLinkClickHandler } from 'react-router-dom';

interface ButtonLinkProps
extends ButtonProps,
Pick<LinkProps, 'replace' | 'state' | 'to'> {
    children?: React.ReactNode;
}

const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(
    ({ onClick, replace = false, state, target, to, ...props },_) => {
        const href = useHref(to);
        const handleClick = useLinkClickHandler(to, { replace, state, target });

        return (
            <Button
                {...props}
                href={href}
                onClick={(event) => {
                    if (!event.defaultPrevented) {
                        handleClick(event as any);
                    }
                }}
                target={target}
            />
        );
    }
);

export default ButtonLink;