import React from "react"
import {List, ListItem, Paper, Typography} from "@mui/material";

interface Props {
    title: string
    items: any
    textModules: any
}

const TourService = (props: Props) => {

    const items = props.items ? props.items.map((item: any) => {
        return {line: item.item}
    }) : []
    const modules = props.textModules ? props.textModules.map((module: any) => {
        return {line: module.text}
    }) : []
    const services = [...items, ...modules]

    return <Paper
        elevation={0}
        sx={{
            background: "rgb(69,147,198,0.2)",
            p: 2,
            flex: 0.4,
            height: "100%"
        }}
    >
        <Typography variant='h6'>{props.title}</Typography>
        {services ?
            <List
                sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside',
                }}
            >
                {services.map((item: any, index: number) => {
                    return <ListItem
                        key={index}
                        sx={{
                            display: "list-item",
                            p: 0,
                        }}
                    >
                        <Typography
                            variant="body1"
                            gutterBottom
                            ml={1}
                            sx={{
                                display: "contents"
                            }}
                        >
                            {item.line}
                        </Typography>
                    </ListItem>
                })}
            </List>
            : ""
        }
    </Paper>

}

export default TourService