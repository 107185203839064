import React from "react";
import {Box} from "@mui/material";

const ResponsiveImage = ({src, circle, nobg, ...props}: any) => {
    const round = circle === true ? "50%" : "0%"
    const isImage = nobg === true
    return <Box {...props}>
        {isImage ?
            <img src={src} style={{height: "100%"}} alt="Silvertip"/>
            :
            <Box
                sx={{
                    backgroundImage: `url(${src})`,
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundColor: "#464646",
                    borderRadius: round,
                    width: "100%",
                    height: "100%"
                }}/>
        }
    </Box>
}

export default ResponsiveImage