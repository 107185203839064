import React, {useState} from "react"
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import SelectTourGroup from "./Components/SelectTourGroup";
import DropZone from "../../../../Components/DropZone";
import DialogSelectPhoto from "../../Photo/DialogSelectPhoto";

interface Props {
    t: any
    tour: any
    onChange: any
    onSetActive: any
    onChangeHeaderFile: any
    onChangeCardFile: any
}


const TourEditGeneral = (props: Props) => {
    const t = props.t
    const tour = props.tour
    const onChange = props.onChange
    const onSetActive = props.onSetActive

    const [showDialogSelectHeaderPhoto, setShowDialogSelectHeaderPhoto] = useState(false)
    const [showDialogSelectCardPhoto, setShowDialogSelectCardPhoto] = useState(false)

    return <>
        <Stack direction="row" gap={1}>
            <TextField
                autoFocus
                required
                sx={{
                    width: '20ch'
                }}
                value={tour.code ? tour.code : ""}
                margin="dense"
                id="code"
                name="code"
                label={t("mgmtTourEdit.code")}
                helperText={t("mgmtTourEdit.helperCode")}
                type="text"
                variant="standard"
                onChange={(event) => onChange("code", event.target.value)}
            />
            <TextField
                required
                fullWidth
                value={tour.name ? tour.name : ""}
                margin="dense"
                id="name"
                name="name"
                label={t("mgmtTourEdit.name")}
                helperText={t("mgmtTourEdit.helperName")}
                type="text"
                variant="standard"
                onChange={(event) => onChange("name", event.target.value)}
            />
        </Stack>
        <Stack direction="row" gap={1} sx={{verticalAlign: "middle"}}>
            <FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            checked={tour.active ? tour.active : false}
                            onChange={(_, d) => onSetActive("active", d)}
                        />
                    }
                    sx={{mt: '14px'}}
                    label={t("generics.active")}
                />
                <FormHelperText sx={{ml: 0, mt: 0}}>{t("mgmtTourEdit.helperActive")}</FormHelperText>
            </FormControl>
            <FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            checked={tour.promotion ? tour.promotion : false}
                            onChange={(_, d) => onSetActive("promotion", d)}
                        />
                    }
                    sx={{mt: '14px'}}
                    label={t("mgmtTourEdit.promote")}
                />
                <FormHelperText sx={{ml: 0, mt: 0}}>{t("mgmtTourEdit.helperPromote")}</FormHelperText>
            </FormControl>
        </Stack>
        <Stack direction="row" gap={1} sx={{verticalAlign: "middle", mt: 2}}>
            <SelectTourGroup t={t} onChange={onChange} value={tour.group}/>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} gap={2} sx={{verticalAlign: "middle", mt: 3}}>
            <Stack direction="column" useFlexGap flexWrap="wrap">
                <Typography variant="subtitle2">{t("mgmtTourEdit.headerImage")}</Typography>
                <Typography variant="body2">{t("mgmtTourEdit.helperHeaderImage")}</Typography>
                <Button onClick={() => setShowDialogSelectHeaderPhoto(true)} sx={{display: "contents"}}>
                    {t("mgmtTourEdit.selectPhoto")}
                </Button>
                <DropZone
                    maxFiles={1}
                    files={[props.tour.headerPhoto]}
                    onChange={props.onChangeHeaderFile}
                    format="header"
                    maxHeight={200}
                    variant="header"/>
                <DialogSelectPhoto
                    open={showDialogSelectHeaderPhoto}
                    show={setShowDialogSelectHeaderPhoto}
                    variant="header"
                    onSelect={props.onChangeHeaderFile}/>
            </Stack>
            <Stack direction="column" useFlexGap flexWrap="wrap">
                <Typography variant="subtitle2">{t("mgmtTourEdit.headerCard")}</Typography>
                <Typography variant="body2">{t("mgmtTourEdit.helperCardImage")}</Typography>
                <Button onClick={() => setShowDialogSelectCardPhoto(true)} sx={{display: "contents"}}>
                    {t("mgmtTourEdit.selectPhoto")}
                </Button>
                <DropZone
                    maxFiles={1}
                    files={[props.tour.cardPhoto]}
                    onChange={props.onChangeCardFile}
                    maxHeight={200}
                    variant="card"/>
                <DialogSelectPhoto
                    open={showDialogSelectCardPhoto}
                    show={setShowDialogSelectCardPhoto}
                    variant="card"
                    onSelect={props.onChangeCardFile}/>
            </Stack>
        </Stack>
    </>
}

export default TourEditGeneral