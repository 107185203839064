import React, {useEffect, useState} from "react"
import PhotoApi from "../../../Api/PhotoApi";
import {DataGrid, GridColDef, GridRowSelectionModel} from "@mui/x-data-grid";
import ResponsiveImage from "../../../Components/ResponsiveImage";
import {Button, Dialog, DialogContent, DialogTitle, IconButton, Stack} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ResizeBox from "../../../Components/ResizeBox";

interface Props {
    open: any
    show: any
    variant?: string | null
    multirow?:boolean | null
    onSelect: any
}

const DialogSelectPhoto = (props: Props) => {
    const {open, show, onSelect} = props
    const variant = props.variant ? props.variant : "impression"
    const multirow = !!props.multirow
    const [photos, setPhotos] = useState([])
    useEffect(() => {
        PhotoApi.GetVariant(variant).then((data: any) => {
            if (data) {
                setPhotos(data.payload.map((p: any) => {
                    return {...p, actionID: p.id}
                }));
            }
        })
    }, [variant]);

    const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([])
    const onRowClick = (id: any) => {
        setRowSelection(id)
    }

    const onAdd = () => {
        const newPhotos: undefined[] = []
        rowSelection.forEach((id) => {
            newPhotos.push(photos.find((item: any) => item.id === id))
        })
        onSelect(newPhotos)
        setRowSelection([])
        show(false)
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "thumb",
            headerName: "Image",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return <ResponsiveImage src={params.value} sx={{height: "50px"}}/>
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5,
            headerClassName: 'tableHeader',
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            headerClassName: 'tableHeader',
        }
    ]


    return <Dialog
        open={open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => show(false)}>
        <DialogTitle sx={{padding: "10px 12px"}}>
            Select Photo(s) to add:
            <IconButton
                aria-label="close"
                onClick={() => show(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}>
                <CloseRoundedIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{padding: "10px 12px"}}>
            <DataGrid
                sx={{
                    height: ResizeBox.Height,
                    ".MuiDataGrid-columnHeaderTitleContainer": {
                        backgroundColor: "aliceblue",
                    },
                }}
                autoPageSize
                rows={photos}
                columns={columns}
                checkboxSelection
                disableMultipleRowSelection={!multirow}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 20, 30]}
                onRowSelectionModelChange={(i: any) => {
                    onRowClick(i)
                }}
                rowSelectionModel={rowSelection}
                rowHeight={39}
                columnHeaderHeight={39}
            />
            <Stack direction="row-reverse">
                <Button onClick={() => onAdd()} disabled={rowSelection.length === 0}>
                    Add
                </Button>
                <Button onClick={() => show(false)}>
                    Cancel
                </Button>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default DialogSelectPhoto