import React, {useEffect, useState} from "react"
import TourApi from "../../Api/TourApi";
import {
    Container,
    Typography,
    useScrollTrigger
} from "@mui/material";
import HeaderBar from "./HeaderBar";
import CardTour from "./CardTour";
import HeaderGroup from "./HeaderGroup";

interface Props {
    id: number
}

const ViewTourGroup = (props: Props) => {

    const [tourGroup, setTourGroup] = useState(TourApi.TourGroupDefinition)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading)
            TourApi.GetPublicGroup(props.id).then((d: any) => {
                setTourGroup(d.payload)
                setLoading(false)
            })
    }, [tourGroup, loading, props.id])

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 600,
    });

    return <>
        {trigger ? <HeaderBar title={tourGroup.name}/> : ""}
        <HeaderGroup
            title={tourGroup.name}
            subtitle={tourGroup.subtitle}
            link={tourGroup.headerPhoto ? tourGroup.headerPhoto.link : ''}
        />
        <Container>
            <Typography variant="body1" mt={4} textAlign="justify" fontSize={"1.2rem"}>
                {tourGroup.description}
            </Typography>
            {/*<Stack id="tour-description" mt={4} spacing={{xs: 2, md: 4}}*/}
            {/*       direction={{xs: "column", sm: "row", md: "row", lg: "row"}}>*/}
            {/*    <div style={{width: "70%"}}>*/}
            {/*        <Typography variant="body1" textAlign="justify" fontSize={"1.2rem"}>*/}
            {/*            {tourGroup.description}*/}
            {/*        </Typography>*/}
            {/*    </div>*/}
            {/*    <div style={{marginTop: "9px", float: "left"}}>*/}
            {/*        <Card sx={{height: {xs: "280px", sm: "300px", md: "320px", lg: "340px"}}}>*/}
            {/*            <Box sx={{position: 'relative'}}>*/}
            {/*                <CardMedia*/}
            {/*                    component="img"*/}
            {/*                    alt={tourGroup.cardPhoto ? tourGroup.cardPhoto.name : ''}*/}
            {/*                    image={tourGroup.cardPhoto ? tourGroup.cardPhoto.link : ''}*/}
            {/*                    sx={{*/}
            {/*                        height: {xs: "280px", sm: "300px", md: "320px", lg: "340px"},*/}
            {/*                        width: {xs: "280px", sm: "300px", md: "320px", lg: "340px"}*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*                <Box*/}
            {/*                    sx={{*/}
            {/*                        position: 'absolute',*/}
            {/*                        bottom: 0,*/}
            {/*                        left: "-10px",*/}
            {/*                        width: '100%',*/}
            {/*                        backgroundColor: 'rgba(102, 102, 102, 0.7)',*/}
            {/*                        color: 'white',*/}
            {/*                        padding: "10px",*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <Typography*/}
            {/*                        variant="h6"*/}
            {/*                        sx={{*/}
            {/*                            overflow: "hidden",*/}
            {/*                            textOverflow: "ellipsis",*/}
            {/*                            display: "-webkit-box",*/}
            {/*                            WebkitLineClamp: "2",*/}
            {/*                            WebkitBoxOrient: "vertical",*/}
            {/*                            textAlign: "center",*/}
            {/*                            fontVariant: "small-caps"*/}
            {/*                        }}>*/}
            {/*                        {tourGroup.subtitle}*/}
            {/*                    </Typography>*/}
            {/*                </Box>*/}
            {/*            </Box>*/}
            {/*        </Card>*/}
            {/*    </div>*/}
            {/*</Stack>*/}
            <Typography variant={"h5"} mt={2} color={"rgb(51,51,51,0.8)"}>
                {tourGroup.tour ? tourGroup.tour.length : 0} Reisen in {tourGroup.name}:
            </Typography>
            <div className="pd2"/>
            <CardTour tours={tourGroup.tour}/>
        </Container>
    </>
}

export default ViewTourGroup