import React from "react"
import {
    Box,
    Card,
    CardMedia,
    createTheme,
    Fade, Stack,
    Typography
} from "@mui/material";
import ExploreIcon from '@mui/icons-material/Explore';

interface Props {
    title: string
    subtitle: string
    link: string
}

const HeaderGroup = (props: Props) => {

    const theme = createTheme();
    theme.typography.h3 = {
        fontSize: '1.4rem',
        '@media (min-width:600px)': {
            fontSize: '1.5rem',
        },
        '@media (min-width:750px)': {
            fontSize: '2rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '5rem',
        },
    };

    return <>
        <Fade in={true} timeout={1000}>
            <Card style={{
                position: "relative",
                justifyContent: "center"
            }}>
                <CardMedia image={props.link} sx={{height: "500px"}} title={props.title}/>
                <Box position={"absolute"}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     height="150px"
                     style={{
                         top: "70%",
                         width: "100%",
                     }}
                >
                    <Box justifyContent="center"
                         alignItems="center"
                         style={{
                             background: "rgb(51,51,51,0.8)",
                             color: "whitesmoke",
                             width: "1158px",
                             borderRadius: "10px",
                         }}>
                        <Stack direction={"row"} sx={{
                            padding: "10px 35px",
                        }}>
                            <ExploreIcon sx={{fontSize: "2.8rem", paddingRight: "0.5rem", mt:"5px"}}/>
                            <Stack direction={"column"}>
                                <div className={"headerImage"}>
                                    {props.title}
                                </div>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="h6"
                                    style={{
                                        color: 'whitesmoke',
                                        fontWeight: 500,
                                        fontVariant: 'small-caps',
                                        backgroundColor: "none",
                                        wordBreak: "break-word"
                                    }}
                                >
                                    {props.subtitle}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
                {/*<Box position="relative"*/}
                {/*                    style={{*/}
                {/*                        // background: 'rgb(102,102,102,0.8)',*/}
                {/*                        marginTop: "-420px",*/}
                {/*                        maxWidth: "1200px",*/}
                {/*                        paddingRight: "20px"*/}
                {/*                    }}*/}
                {/*               >*/}
                {/*                   <Box>*/}
                {/*                       <ThemeProvider theme={theme}>*/}
                {/*                           <Typography*/}
                {/*                               variant='h3'*/}
                {/*                               sx={{*/}
                {/*                                   fontWeight: 500,*/}
                {/*                                   color: 'white',*/}
                {/*                                   fontVariant: 'small-caps',*/}
                {/*                               }}*/}
                {/*                           >*/}
                {/*                               {props.title}*/}
                {/*                           </Typography>*/}
                {/*                       </ThemeProvider>*/}
                {/*                   </Box>*/}
                {/*               </Box>*/}
                {/*               <Box position="relative"*/}
                {/*                    style={{*/}
                {/*                        background: 'rgb(152,152,152,0.9)',*/}
                {/*                        height: '56px',*/}
                {/*                        marginTop: "-80px",*/}
                {/*                        maxWidth: "1200px",*/}
                {/*                        width: "50%",*/}
                {/*                    }}*/}
                {/*               >TEST*/}
                {/*               </Box>*/}


            </Card>

        </Fade>
    </>
}

export default HeaderGroup