import React, {useEffect} from "react";
import {useImmer} from "use-immer";
import IAM from "../../../Common/IAM";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField,
    Typography,
    Select,
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox, Box
} from "@mui/material";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import {DataGrid, GridColDef} from '@mui/x-data-grid';


const User = () => {

    const loginDefinition = {
        "id": 0,
        "login": "",
        "password": "",
        "rePassword": "",
        "active": false,
        "role": {"name": ""}
    }

    const [open, setOpen] = React.useState(false)
    const [paswdMatch, setPaswdMatch] = React.useState(false)
    const [passwdLength, setPasswdLength] = React.useState(false)
    const [changed, setChanged] = React.useState(false)
    const [users, setUsers] = React.useState([loginDefinition]);
    const [login, setLogin] = useImmer(loginDefinition)
    const [roles, setRoles] = React.useState([])

    if (roles.length === 0) {
        IAM.GetRoles().then((data: any) => {
            setRoles(data)
        })
    }

    useEffect(() => {
        IAM.GetUsers().then((data: any) => {
            const filtered = data.payload.map((d: any) => {
                return {
                    id: d.id,
                    login: d.login,
                    role: d.role.name,
                    active: d.active
                }
            })
            console.log(JSON.stringify(filtered))
            setUsers(filtered);
            setChanged(false)
        })
    }, [changed]);

    const onChange = (e: any) => {
        e.preventDefault()
        const value = e.target.value ? e.target.value : ""
        const key = e.target.name ? e.target.name : ""
        setLogin((draft) => {
            switch (key) {
                case "login": {
                    draft.login = value
                    break
                }
                case "password": {
                    draft.password = value
                    if (draft.password.length < 8) {
                        setPasswdLength(true)
                    } else {
                        setPasswdLength(false)
                    }
                    break
                }
                case "rePassword": {
                    draft.rePassword = value
                    if (draft.password !== draft.rePassword) {
                        setPaswdMatch(true)
                    } else {
                        setPaswdMatch(false)
                    }
                    break
                }
            }
        })
    }

    const onSelect = (e: any) => (
        setLogin((draft) => {
            draft.role.name = e.target.value
        })
    )
    //
    const onSetActive = (checked: boolean | undefined) => (
        setLogin((draft) => {
            draft.active = checked ? checked : false
        })
    )

    const onSave = () => {
        IAM.PersistUser(login)
            .then(() => {
                setChanged(true)
                setOpen(false)
                setLogin(loginDefinition)
            })
            .catch((data: any) => {
                console.log(data);
            });
    }

    const onRowClick = (id: number) => {
        IAM.GetUserDetail(id).then((data: any) => {
            setLogin((draft) => {
                draft.id = data.payload.id
                draft.login = data.payload.login
                draft.active = data.payload.active
                draft.role.name = data.payload.role.name
            })
            setOpen(true)
        })
    }

    const handleClose = () => {
        setOpen(false);
        setLogin(loginDefinition)
    };

    const dialogUser = () => {
        return <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    onSave()
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" alignItems="center" gap={1}>
                    <ManageAccountsOutlinedIcon fontSize='large'/>
                    <Typography variant='h5'> {login.id > 0 ? "Edit User" : "New User"}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please add or modify the user data.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    value={login.login ? login.login : ''}
                    margin="dense"
                    id="login"
                    name="login"
                    label="User name or email address"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e: any) => onChange(e)}
                />
                <TextField
                    required={!login.id}
                    error={passwdLength}
                    helperText={passwdLength ? "Your password must be 8 characters or more" : ""}
                    margin="dense"
                    id="password"
                    name="password"
                    label={login.id ? "Change Password" : "Password"}
                    type="password"
                    fullWidth
                    variant="standard"
                    onChange={(e: any) => onChange(e)}
                />
                <TextField
                    required={!login.id}
                    error={paswdMatch}
                    helperText={paswdMatch ? "Your entered passwords do not match" : ""}
                    margin="dense"
                    id="rePassword"
                    name="rePassword"
                    label="Re-enter password"
                    type="password"
                    fullWidth
                    variant="standard"
                    onChange={(e: any) => onChange(e)}
                />

                <FormControl sx={{mt: 2, minWidth: 200}} variant="standard">
                    <InputLabel id="label-role">Role</InputLabel>
                    <Select
                        labelId="label-role"
                        sx={{minWidth: 200}}
                        id="role"
                        value={login.role.name ? login.role.name : ''}
                        label="Role"
                        onChange={(e: any) => onSelect(e)}
                    >
                        {roles.map((r: any) => {
                            return <MenuItem value={r.value}>{r.text}</MenuItem>
                        })}

                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={login.active}
                            onChange={(_, d) => onSetActive(d)}
                        />
                    }
                    label="Active"
                    sx={{mt: '26px', ml: '15px'}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Save</Button>
            </DialogActions>
        </Dialog>
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.2,
            headerClassName: 'tableHeader',
        },
        {
            field: 'login',
            headerName: 'Login',
            flex: 2,
            headerClassName: 'tableHeader',
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            headerClassName: 'tableHeader',
        },
        {
            field: 'active',
            headerName: 'Active',
            type: "boolean",
            flex: 0.5,
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return params.value ? "yes" : "no"
            }
        },
    ];

    return (
        <Box sx={{height: '80vh', width: '100%'}}>
            {dialogUser()}
            <Stack direction="row" alignItems="center" gap={1}>
                <PeopleAltOutlinedIcon fontSize='medium'/>
                <Typography variant='h5'>User</Typography>
            </Stack>
            <div className='pd1'/>
            <Button size="small" onClick={() => setOpen(true)}>
                Add a user
            </Button>
            <DataGrid
                autoPageSize
                rows={users}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 20, 30]}
                onRowSelectionModelChange={(i: any) => {
                    onRowClick(i)
                }}
            />
        </Box>
    )
}

export default User