import React from "react";
import {useState, useEffect} from "react";

const ProgressiveImage = ({placeholder, src, ...props}: any) => {
    const [imgSrc, setImgSrc] = useState(placeholder || src);
    const sx = props.style ? props.style : ""

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setImgSrc(src);
        };
    }, [src]);

    const loading = {
        filter: "blur(10px)",
        ...sx
    }

    const loaded = {
        filter: "blur(0px)",
        transition: "filter 0.5s linear",
        ...sx
    }
    const style =
        placeholder && imgSrc === placeholder ? loading : loaded;

    return (
        <img
            {...{src: imgSrc, ...props}}
            alt={props.alt || ""}
            className={props.className}
            style={style}
        />
    );
};
export default ProgressiveImage;