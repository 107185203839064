import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    value: any
    onChange: any
    tabs: any
}

const MgmtPageTabs = (props: Props) => {
    const {t} = useTranslation();
    return (
        <TabContext value={props.value}>
            <TabList onChange={props.onChange} aria-label="tour-edit" style={{backgroundColor: "aliceblue"}}>
                <Tab label={t("mgmtTourEdit.tabGeneral")} value="general" key="general"></Tab>
                <Tab label={t("mgmtTourEdit.tabOptions")} value="options" key="options"></Tab>
                <Tab label={t("mgmtTourEdit.tabDescription")} value="description" key="description"></Tab>
                <Tab label={t("mgmtTourEdit.tabProgram")} value="program" key="program"></Tab>
                <Tab label={t("mgmtTourEdit.tabProgramInfo")} value="info" key="info"></Tab>
                <Tab label={t("mgmtTourEdit.tabServices")} value="services" key="services"></Tab>
                <Tab label={t("mgmtTourEdit.tabGroupDates")} value="dates" key="dates"></Tab>
                <Tab label={t("mgmtTourEdit.tabIndividualDates")} value="individual" key="individual"></Tab>
                <Tab label={t("mgmtTourEdit.tabImpressions")} value="impressions" key="impressions"></Tab>
                <Tab label={t("mgmtTourEdit.tabInternal")} value="internal" key="internal"></Tab>
            </TabList>
            {props.tabs.map((tab: any, index: number) => {
                return <TabPanel key={index} value={tab.value} sx={{p: 1}}>
                    {tab.element}
                </TabPanel>
            })}
        </TabContext>
    )
}

export default MgmtPageTabs;