import ConfigGeneral from "../Config/ConfigGeneral";

const IAM: any = {
    GetRoles: async () => {
        interface role {
            key: string,
            value: string,
            text: string,
        }

        let roles: role[] = []
        const res = await fetch(ConfigGeneral.API_URL + '/iam/role', {
            method: "GET",
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("st-jwt")
            }
        })
        if (!res.ok) {
            return null
        }
        return await res.json().then(data => {
            data.payload.map((d: any) => {
                return roles.push({text: d.name, value: d.name, key: d.id})
            })
            return roles
        })
    },

    GetUserDetail: async (id: number) => {
        const res = await fetch(ConfigGeneral.API_URL + '/iam/user/' + id, {
            method: "GET",
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("st-jwt")
            }
        })
        if (!res.ok) {
            return null
        }
        return await res.json().then(data => {
            return data
        })
    },

    GetUsers: async () => {
        const res = await fetch(ConfigGeneral.API_URL + '/iam/user', {
            method: "GET",
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("st-jwt")
            }
        })
        if (!res.ok) {
            return null
        }
        return await res.json().then(data => {
            return data
        })
    },

    PersistUser: async (data: any) => {
        let method, id: string
        if (data.id > 0) {
            method = "PUT"
            id = "/" + data.id
        } else {
            method = "POST"
            id = ""
        }
        const res = await fetch(ConfigGeneral.API_URL + '/iam/user' + id, {
            method: method,
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("st-jwt")
            },
            body: JSON.stringify(data)
        })
        if (!res.ok) {
            return null
        }
        return await res.json().then(data => {
            return data
        })
    },

    TokenValid: async (token: string) => {
        if (!token || token.length === 0) {
            return false
        }
        let res;
        try {
            res = await fetch(ConfigGeneral.API_URL + '/iam/login/' + token, {
                method: "GET",
                headers: {
                    'content-type': 'application/json;charset=UTF-8'
                },
            })
            const {data, errors} = await res.json()
            if (!res.ok) {
                console.log(errors)
                return false
            } else {
                if (data.exp) {
                    return true
                }
            }
        } catch (err) {
            return false
        }
    }
}

export default IAM