import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import TourApi from "../../../../Api/TourApi";
import ResizeBox from "../../../../Components/ResizeBox";
import {Button} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import ResponsiveImage from "../../../../Components/ResponsiveImage";
import MgmtPageTitle from "../../Components/MgmtPageTitle";

const TourGroupOverview = () => {
    const navigate = useNavigate();
    const [groups, setGroups] = React.useState([TourApi.TourGroupDefinition]);
    const [changed, setChanged] = React.useState(false)

    useEffect(() => {
        TourApi.GetGroups().then((data: any) => {
            if (data.payload) {
                setGroups(data.payload);
            }
            setChanged(false)
        })
    }, [changed]);

    const onRowClick = (id: number) => {
        navigate("/mgmt/tour/group/edit/" + id, {replace: true})
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "cardPhoto",
            headerName: "Image",
            headerClassName: 'tableHeader',
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const url = params.value && params.value.thumb ? params.value.thumb : ""
                return <ResponsiveImage src={url} sx={{height: "50px"}}/>
            }
        },
        {
            field: 'orderID',
            headerName: 'Order',
            headerClassName: 'tableHeader',
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return params.value ? String(params.value) : "0"
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerClassName: 'tableHeader',
        },
        {
            field: 'subtitle',
            headerName: 'Subtitle',
            flex: 2,
            headerClassName: 'tableHeader',
        },
        {
            field: "active",
            headerName: "Active",
            align: "center",
            headerAlign: "center",
            flex: 0.5,
            headerClassName: "tableHeader",
            renderCell: (params) => {
                return params.value ? "yes" : "no"
            }
        },
    ];

    return <ResizeBox.Box>
        <MgmtPageTitle label="mgmtTourGroup.groups" icon="holiday_village"/>
        <Button size="small" onClick={() => navigate("/mgmt/tour/group/edit", {replace: true})}>
            Add a Tour Group
        </Button>

        <DataGrid
            sx={{height: ResizeBox.Height}}
            autoPageSize
            rowHeight={39}
            columnHeaderHeight={39}
            rows={groups}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
            onRowSelectionModelChange={(i: any) => {
                onRowClick(i)
            }}
        />
    </ResizeBox.Box>
}

export default TourGroupOverview