import React from "react";
import ButtonLink from '../Components/ButtonLink';
import {Segment} from "semantic-ui-react";

const Info = () => (
    <Segment basic>
        <h1 className="header">This is APP Template Info</h1>
        <ButtonLink to="/">Go to Home</ButtonLink>
    </Segment>
)

export default Info