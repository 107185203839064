import React, {useEffect, useMemo, useState} from "react"
import {useImmer} from "use-immer";
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputAdornment, InputLabel,
    ListItemIcon, ListItemText, MenuItem, Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";

import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import TerrainOutlinedIcon from '@mui/icons-material/TerrainOutlined';

import {SelectChangeEvent} from '@mui/material/Select';
import ProgramTimeline from "../../../../Components/ProgramTimeline";
import ConfigTour from "../../../../Config/ConfigTour";
import SelectTextModule from "./Components/SelectTextModule";
import {TFunction} from "i18next";

interface Props {
    t: TFunction
    onChange: any
    value: any
}

const TourEditProgram = (props: Props) => {
    const t = props.t

    interface ProgramItem {
        id: number | null
        headline: string
        prefix: string
        type: string
        description: string
        accommodation: string
        activity: string
        listItem: ProgramListItem[],
        text: any
        order: number
        toDelete: boolean
    }

    interface ProgramListItem {
        item: string
        order: number
        toDelete: boolean
    }

    const listItem: ProgramListItem = {
        item: "",
        order: 0,
        toDelete: false
    }

    const item: ProgramItem = useMemo(() => {
        return {
            id: null,
            headline: "",
            prefix: "",
            type: "",
            description: "",
            accommodation: "",
            activity: "",
            listItem: [] as ProgramListItem[],
            text: [],
            order: 0,
            toDelete: false
        }
    }, [])

    const [program, setProgram] = useImmer<ProgramItem[]>([])
    const [showDialogText, setShowDialogText] = useState(false)
    const [showDialogTextModule, setDialogTextModule] = useState(false)
    const [newProgramItem, setNewProgramItem] = useImmer<ProgramItem>(item)
    const [addProgramItem, setAddProgramItem] = useState(false)
    const [editItem, setEditItem] = useState(-1)
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        if (addProgramItem) {
            if (program && program.length > 0) {
                setProgram(draft => {
                    draft.push(newProgramItem)
                })
            } else {
                setProgram([newProgramItem])
            }
            setAddProgramItem(false)
            setNewProgramItem(item)
            setUpdate(true)
        }
    }, [addProgramItem, program, setNewProgramItem, newProgramItem, setProgram, item])

    useEffect(() => {
        if (update) {
            props.onChange(program)
            setUpdate(false)
        }
    }, [update, program, props])

    useEffect(() => {
       setProgram(props.value)
    }, [props, setProgram])

    const onAdd = () => {
        setShowDialogText(false)
        setNewProgramItem(draft => {
            draft.order = program ? program.length === 0 ? 1 : program[program.length - 1].order + 1 : 1
            // Remove empty items from array
            draft.listItem = newProgramItem.listItem.filter((d) => d.item.length !== 0)
        })
        setAddProgramItem(true)
    }

    const removeDeletedItems = (program: ProgramItem[]) => {
        return program.filter((item: ProgramItem) => !item.toDelete)
    }

    const onChange = (key: string, value: any) => {
        setNewProgramItem((draft) => {
            switch (key) {
                case "prefix": {
                    draft.prefix = value
                    break
                }
                case "type": {
                    draft.type = value
                    break
                }
                case "headline": {
                    draft.headline = value
                    break
                }
                case "accommodation": {
                    draft.accommodation = value
                    break
                }
                case "activity": {
                    draft.activity = value
                    break
                }
                case "description": {
                    draft.description = value
                    break
                }
            }
        })
    }

    const onChangeItem = (e: any, index: number) => {
        const value = e.target.value ? e.target.value : ""
        setNewProgramItem((draft) => {
                draft.listItem[index].item = value
            }
        )
    }

    const addListItem = () => {
        const count = newProgramItem.listItem.length
        if (newProgramItem.listItem[count - 1].item.length > 0) {
            setNewProgramItem((draft) => {
                    draft.listItem.push({item: "", order: count, toDelete: false})
                }
            )
        }
    }

    const onKey = (e: any) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            addListItem()
        }
    }

    const onDeleteItem = (index: number) => {
        if (program) {
            if (program[index].id) {
                setProgram(draft => {
                    draft[index].toDelete = true
                })
            } else {
                setProgram(draft => {
                    draft.splice(index, 1)
                })

            }
            setUpdate(true)
        }
    }

    const onChangeProgram = (e: any, i: number, indexItem: number) => {
        const value = e.target.value ? e.target.value : ""
        const key = e.target.name ? e.target.name : ""
        setProgram(draft => {
            switch (key) {
                case "headline": {
                    draft[i].headline = value
                    break
                }
                case "description": {
                    draft[i].description = value
                    break
                }
                case "type": {
                    draft[i].type = value
                    break
                }
                case "prefix": {
                    draft[i].prefix = value
                    break
                }
                case "accommodation": {
                    draft[i].accommodation = value
                    break
                }
                case "activity": {
                    draft[i].activity = value
                    break
                }
                case "item": {
                    if (value.length > 0) {
                        draft[i].listItem[indexItem].item = value
                    } else {
                        draft[i].listItem[indexItem].item = ""
                        draft[i].listItem[indexItem].toDelete = true
                    }
                }
            }
        })
        setUpdate(true)
    }

    const onKeyChangeProgram = (e: any, i: number) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            if (program[i].listItem[program[i].listItem.length - 1].item.length > 0) {
                setProgram(draft => {
                    draft[i].listItem.push(listItem)
                })
                setUpdate(true)
            }
        }
    }

    const swapItem = (index: number, direction: string) => {
        setProgram((draft) => {
            switch (direction) {
                case "up": {
                    const up = {...program[index]}
                    const down = {...program[index - 1]}
                    up.order = program[index - 1].order
                    down.order = program[index].order
                    draft[index - 1] = up
                    draft[index] = down
                    break
                }
                case "down": {
                    const down = {...program[index]}
                    const up = {...program[index + 1]}
                    down.order = program[index + 1].order
                    up.order = program[index].order
                    draft[index + 1] = down
                    draft[index] = up
                    break
                }
            }
        })
        setUpdate(true)
    }

    const renderActionButton = (index: number) => {
        return <Stack direction="row" gap={1}>
            {index > 0 ?
                <KeyboardArrowUpRoundedIcon
                    name='arrow up'
                    fontSize='small'
                    onClick={() => swapItem(index, "up")}
                />
                : ''}
            {index < program.length - 1 ?
                <KeyboardArrowDownRoundedIcon
                    name='arrow down'
                    fontSize='small'
                    onClick={() => swapItem(index, "down")}
                />
                : ''}
            <EditNoteOutlinedIcon
                name='edit'
                fontSize='small'
                onClick={() => {
                    setEditItem(index)
                    setShowDialogText(true)
                }}/>
            <DeleteOutlineRoundedIcon
                name='trash'
                color="warning"
                fontSize='small'
                onClick={() => onDeleteItem(index)}/>
        </Stack>
    }

    const renderProgram = (program: any) => {
        return <ProgramTimeline program={removeDeletedItems(program)} actionButton={renderActionButton}/>
    }

    const RenderAddButtons = () => {
        return <Stack direction="row">
            <Button
                onClick={() => {
                    setNewProgramItem(item)
                    setShowDialogText(true)
                }}
            >
                {t("mgmtTourEdit.addText")}
            </Button>
            <Button
                onClick={() => {
                    const setDummy = {
                        id: null,
                        headline: "",
                        prefix: "",
                        type: "",
                        description: "",
                        accommodation: "",
                        activity: "",
                        listItem: [] as ProgramListItem[],
                        text: [],
                        order: 0,
                        toDelete: false
                    }
                    setDummy.listItem.push(listItem)
                    setNewProgramItem(setDummy)
                    setShowDialogText(true)
                }}
            >
                {t("mgmtTourEdit.addList")}
            </Button>
            <Button onClick={() => {
                setNewProgramItem(item)
                setDialogTextModule(true)
            }}>
                {t("mgmtTourEdit.addTextModule")}
            </Button>
        </Stack>
    }

    const DialogTextModule = () => {
        const item = editItem >= 0 ? program[editItem] : newProgramItem
        const isNew = editItem === -1
        const [selectedTextModule, setSelectedTextModule] = useState(item.text && item.text[0] ? item.text[0] : "")

        const onSelectTextModule = (value: any) => {
            setSelectedTextModule([value])
        }

        const saveSelected = () => {
            setNewProgramItem((draft) => {
                draft.text = selectedTextModule
            })
        }

        return <Dialog
            open={showDialogTextModule}
            fullWidth
            maxWidth={"lg"}
        > <DialogTitle>
            {t("mgmtTourEdit.headerAddTextModule")}
        </DialogTitle>
            <DialogContent>
                <Stack direction="column" width={"100%"}>
                    <Stack direction="row" useFlexGap>
                        <FormControl variant="standard" sx={{m: "7px", minWidth: 120}}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                id="type"
                                name="type"
                                value={item.type ? item.type : "info"}
                                variant="standard"
                                disabled={true}
                                margin="dense"
                                label={t("mgmtTourEdit.type")}
                                SelectDisplayProps={{
                                    style: {display: 'flex', alignItems: 'center'},
                                }}
                            >
                                <MenuItem value="">
                                    <em>{t("mgmtTourEdit.selectType")}</em>
                                </MenuItem>
                                {ConfigTour.ProgramTypeOptions.map((item: any, index: number) => {
                                    return <MenuItem value={item.value} key={index}>
                                        <ListItemIcon sx={{minWidth: 36}}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={t(item.label)} sx={{my: 0}}/>
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                    <SelectTextModule t={t}
                                      onSelect={onSelectTextModule} selected={selectedTextModule.id}
                                      filter={"program"}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row">
                    <Button onClick={() => {
                        setDialogTextModule(false)
                        setNewProgramItem(item)
                        setEditItem(-1)
                    }}>
                        {t("generics.cancel")}
                    </Button>
                    <Button onClick={isNew ?
                        () => {
                            saveSelected()
                            setNewProgramItem(draft => {
                                draft.order = program ? program.length === 0 ? 1 : program[program.length - 1].order + 1 : 1
                                draft.type = "info"
                            })
                            setDialogTextModule(false)
                            setAddProgramItem(true)
                        }
                        : () => {
                            saveSelected()
                            setNewProgramItem(item)
                            setEditItem(-1)
                            setDialogTextModule(false)
                        }
                    }>
                        {t("generics.save")}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    }

    const dialogAddEdit = () => {
        const newItem = editItem >= 0 ? program[editItem] : newProgramItem
        const isNew = editItem === -1
        return <Dialog
            open={showDialogText}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle>
                {t("mgmtTourEdit.addProgramItem")}
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" width={"100%"}>
                    <Stack direction="row" useFlexGap>
                        <TextField
                            value={newItem.prefix ? newItem.prefix : ''}
                            margin="dense"
                            id="prefix"
                            name="prefix"
                            label="Prefix"
                            type="text"
                            variant="standard"
                            helperText={t("mgmtTourEdit.helperPrefix")}
                            onChange={isNew ?
                                (event) => onChange("prefix", event.target.value)
                                : (event) => onChangeProgram(event, editItem, 0)
                            }
                            sx={{width: "10ch"}}
                        />
                        <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                            <InputLabel>{t("mgmtTourEdit.type")}</InputLabel>
                            <Select
                                id="type"
                                name="type"
                                value={newItem.type ? newItem.type : ''}
                                variant="standard"
                                margin="dense"
                                onChange={isNew ?
                                    (event: SelectChangeEvent) => onChange("type", event.target.value)
                                    : (event: SelectChangeEvent) => onChangeProgram(event, editItem, 0)
                                }
                                label={t("mgmtTourEdit.type")}
                                SelectDisplayProps={{
                                    style: {display: 'flex', alignItems: 'center'},
                                }}
                            >
                                <MenuItem value="">
                                    <em>{t("mgmtTourEdit.selectType")}</em>
                                </MenuItem>
                                {ConfigTour.ProgramTypeOptions.map((item: any, index: number) => {
                                    return <MenuItem value={item.value} key={index}>
                                        <ListItemIcon sx={{minWidth: 36}}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={t(item.label)} sx={{my: 0}}/>
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                    <TextField
                        fullWidth
                        value={newItem.headline ? newItem.headline : ''}
                        margin="dense"
                        id="headline"
                        name="headline"
                        label={t("mgmtTourEdit.headline")}
                        type="text"
                        variant="standard"
                        helperText={t("mgmtTourEdit.helperHeadline")}
                        onChange={isNew ?
                            (event) => onChange("headline", event.target.value)
                            : (event) => onChangeProgram(event, editItem, 0)
                        }
                    />
                    {newItem.listItem.length === 0 ?
                        <>
                            <TextField
                                fullWidth
                                value={newItem.description ? newItem.description : ''}
                                margin="dense"
                                id="description"
                                name="description"
                                label={t("generics.description")}
                                type="text"
                                multiline
                                variant="filled"
                                rows={5}
                                onChange={isNew ?
                                    (event) => onChange("description", event.target.value)
                                    : (event) => onChangeProgram(event, editItem, 0)
                                }
                            />
                            <Stack direction="row" useFlexGap gap={1}>
                                <TextField
                                    value={newItem.accommodation ? newItem.accommodation : ''}
                                    margin="dense"
                                    id="accommodation"
                                    name="accommodation"
                                    label={t("mgmtTourEdit.accommodation")}
                                    type="text"
                                    variant="standard"
                                    helperText={t("mgmtTourEdit.helperAccommodation")}
                                    onChange={isNew ?
                                        (event) => onChange("accommodation", event.target.value)
                                        : (event) => onChangeProgram(event, editItem, 0)
                                    }
                                    sx={{flex: 1}}
                                    InputProps={{
                                        startAdornment: <InputAdornment
                                            position="start"><HotelOutlinedIcon/></InputAdornment>,
                                    }}
                                />
                                <TextField
                                    value={newItem.activity ? newItem.activity : ''}
                                    margin="dense"
                                    id="activity"
                                    name="activity"
                                    label={t("mgmtTourEdit.activity")}
                                    type="text"
                                    variant="standard"
                                    helperText={t("mgmtTourEdit.helperActivity")}
                                    onChange={isNew ?
                                        (event) => onChange("activity", event.target.value)
                                        : (event) => onChangeProgram(event, editItem, 0)
                                    }
                                    sx={{flex: 1}}
                                    InputProps={{
                                        startAdornment: <InputAdornment
                                            position="start"><TerrainOutlinedIcon/></InputAdornment>,
                                    }}
                                />
                            </Stack>
                        </>
                        : newItem.description.length === 0 && newItem.listItem.length > 0 ?
                            newItem.listItem.map((listItem, index) => {
                                return <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end'
                                    }}
                                    key={index}
                                >
                                    <Typography
                                        sx={{
                                            color: 'action.active',
                                            mr: 1,
                                            marginBottom: "8px",
                                            width: "12ch"
                                        }}
                                        variant="caption"
                                    >
                                        {t("mgmtTourEdit.listItem") + " " + index + ":"}
                                    </Typography>
                                    <TextField
                                        autoFocus={index > 0}
                                        fullWidth
                                        value={listItem.item ? listItem.item : ''}
                                        margin="dense"
                                        id="item"
                                        name="item"
                                        size="small"
                                        type="text"
                                        variant="standard"
                                        onKeyDown={isNew ?
                                            onKey
                                            :
                                            (event: any) => onKeyChangeProgram(event, editItem)
                                        }
                                        onChange={isNew ?
                                            (event) => onChangeItem(event, index)
                                            : (event) => onChangeProgram(event, editItem, index)
                                        }
                                    />
                                </Box>
                            })
                            : ''
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row">
                    <Button onClick={() => {
                        setShowDialogText(false)
                        setNewProgramItem(item)
                        setEditItem(-1)
                    }}>
                        {t("generics.cancel")}
                    </Button>
                    <Button onClick={isNew ?
                        onAdd
                        : () => {
                            setShowDialogText(false)
                            setNewProgramItem(item)
                            setEditItem(-1)
                        }
                    }>
                        {t("generics.save")}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    }

    return <Stack mt={2}>
        <RenderAddButtons/>
        {program ? renderProgram(program) : ""}
        {dialogAddEdit()}
        <DialogTextModule/>
    </Stack>

}
export default TourEditProgram