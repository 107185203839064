import React from "react"
import {Autocomplete, Chip, Stack, TextField} from "@mui/material";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import UmbrellaIcon from '@mui/icons-material/Umbrella';

interface Props {
    t:any
    onChange: any
    value: any
}

const SelectSeason = (props: Props) => {
    const t = props.t
    const options = [
        {id: 1, value: "january", label: t("dateTime.january"), icon: "snowflake"},
        {id: 2, value: "february", label: t("dateTime.february"), icon: "snowflake"},
        {id: 3, value: "march", label: t("dateTime.march"), icon: "snowflake outline"},
        {id: 4, value: "april", label: t("dateTime.april"), icon: "rain"},
        {id: 5, value: "may", label: t("dateTime.may"), icon: "sun outline"},
        {id: 6, value: "june", label: t("dateTime.june"), icon: "sun"},
        {id: 7, value: "july", label: t("dateTime.july"), icon: "sun"},
        {id: 8, value: "august", label: t("dateTime.august"), icon: "sun"},
        {id: 9, value: "september", label: t("dateTime.september"), icon: "sun outline"},
        {id: 10, value: "october", label: t("dateTime.october"), icon: "cloud"},
        {id: 11, value: "november", label: t("dateTime.november"), icon: "cloud"},
        {id: 12, value: "december", label: t("dateTime.december"), icon: "snowflake outline"},
    ]

    const selected = () => {
        const a = []
        if (props.value.january) a.push(options.find((o) => o.id === 1))
        if (props.value.february) a.push(options.find((o) => o.id === 2))
        if (props.value.march) a.push(options.find((o) => o.id === 3))
        if (props.value.april) a.push(options.find((o) => o.id === 4))
        if (props.value.may) a.push(options.find((o) => o.id === 5))
        if (props.value.june) a.push(options.find((o) => o.id === 6))
        if (props.value.july) a.push(options.find((o) => o.id === 7))
        if (props.value.august) a.push(options.find((o) => o.id === 8))
        if (props.value.september) a.push(options.find((o) => o.id === 9))
        if (props.value.october) a.push(options.find((o) => o.id === 10))
        if (props.value.november) a.push(options.find((o) => o.id === 11))
        if (props.value.december) a.push(options.find((o) => o.id === 12))
        return a
    }

    const CustomChip = (props: any) => {
        return <Chip
            {...props}
            color={getColor(props.value)}
            icon={getIcon(props.value)}/>
    }

    const getColor = (month: string) => {
        switch (month) {
            case "january" :
            case "february" :
            case "march" :
                return "primary"
            case "april" :
            case "may" :
            case "june" :
                return "success"
            case "july" :
            case "august" :
            case "september" :
                return "warning"
            case "october" :
            case "november" :
            case "december" :
                return "secondary"
        }
    }

    const getIcon = (month: string) => {
        switch (month) {
            case "january" :
            case "february" :
            case "march" :
                return <AcUnitIcon/>
            case "april" :
            case "may" :
            case "june" :
                return <LocalFloristIcon/>
            case "july" :
            case "august" :
            case "september" :
                return <WbSunnyIcon/>
            case "october" :
            case "november" :
            case "december" :
                return <UmbrellaIcon/>
        }
    }

    const onChange = (value: any) => {
        const selection = value.length > 0 ? value : []
        const season = {
            january: !!selection.find((d: any) => d.value === "january"),
            february: !!selection.find((d: any) => d.value === "february"),
            march: !!selection.find((d: any) => d.value === "march"),
            april: !!selection.find((d: any) => d.value === "april"),
            may: !!selection.find((d: any) => d.value === "may"),
            june: !!selection.find((d: any) => d.value === "june"),
            july: !!selection.find((d: any) => d.value === "july"),
            august: !!selection.find((d: any) => d.value === "august"),
            september: !!selection.find((d: any) => d.value === "september"),
            october: !!selection.find((d: any) => d.value === "october"),
            november: !!selection.find((d: any) => d.value === "november"),
            december: !!selection.find((d: any) => d.value === "december"),
        }
        props.onChange(season)
    }

    return <Stack width="100%">
        <Autocomplete
            fullWidth
            multiple
            id="tour-season"
            options={options}
            value={selected()}
            getOptionKey={(option: any) => option.value}
            getOptionLabel={(option: any) => option.label}
            isOptionEqualToValue={(option, value) => {
                if (option && value) {
                    return option.value === value.value
                } else {
                    return false
                }
            }}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                    <CustomChip {...getTagProps({index})}
                                label={option ? option.label : ""}
                                value={option ? option.value : ""}
                    />
                ));
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={props.value.yearAround ?
                        <span style={{color:"red", marginLeft:10}}>Months not bookable for year-round tours</span>
                        : <span style={{marginLeft:10}}>{t("mgmtTourEdit.tourSeason")}</span>}
                />
            )}
            onChange={(_, d) => onChange(d)}
        />
    </Stack>
}

export default SelectSeason
