import {
    Button,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";

interface Props {
    filter: string
    price: any
}

const TourPrice = (props: Props) => {
    if (!props.price) return <></>
    const {filter, price} = props
    const formatPrice = (price: number) => {
        return String(price.toFixed(2)).replace("/.", ",")
    }

    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.body}`]: {
            fontSize: "1rem",
        },
    }));
    const StyledTableRow = styled(TableRow)(() => ({
        [`'&:last-child td, &:last-child th'`]: {
            border: 0,
        },
    }));

    let filtered: any
    let title = ""
    let subtitle = ""
    switch (filter) {
        case "option" : {
            filtered = price.filter((p: any) => p.isOption === true)
            subtitle = "Optional"
            break
        }
        case "group" : {
            filtered = price.filter((p: any) => p.isGroup === true)
            subtitle = "Optional"
            break
        }
        case "pax" : {
            filtered = price.filter((p: any) => p.isPax === true)
            title = "Individuelle Termine"
            subtitle = "Preis pro Person"
            break
        }
    }

    if (!filtered.length) return <></>

    return <TableContainer component={Paper} sx={{mt: 2}} elevation={0}>
        {title ?
            <Typography variant="h6">
                {title}
            </Typography> : ""}
        {subtitle ?
            <Typography variant="subtitle1" sx={{fontWeight: 600, mt:2}}>
                {subtitle}
            </Typography> : ""}
        <Table sx={{minWidth: 650, mt: 1}} aria-label="simple table">
            <TableBody>
                {filtered.map((row: any) => (
                    row.isPax ?
                        <StyledTableRow key={row.id}>
                            <StyledTableCell align="left" sx={{width: "20ch", padding: 1}}>
                                {row.min} {row.max ? " bis " + row.max : ""} Teilnehmer
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{padding: 1}}>
                                {row.description ? row.description : ""}
                            </StyledTableCell>
                            <StyledTableCell align="right" sx={{padding: 1}}>
                                {formatPrice(row.price)} €
                            </StyledTableCell>
                            <StyledTableCell align="right" sx={{width: "10ch", padding: 1}}>
                                <Button variant="contained" disableElevation size="small" sx={{
                                    backgroundColor:"rgb(69,147,198,0.8)",
                                    '&:hover': {
                                        backgroundColor: "rgb(69,147,198,1)"
                                    }
                                }}>
                                    Anfragen
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                        : row.isOption || row.isGroup ?
                            <StyledTableRow key={row.id}>
                                <StyledTableCell align="left" sx={{padding: 1}}>
                                    {row.name ? row.name : ""}
                                </StyledTableCell>
                                <StyledTableCell align="left" sx={{padding: 1}}>
                                    {row.description ? row.description : ""}
                                </StyledTableCell>
                                <StyledTableCell align="right" sx={{padding: 1}}>
                                    {formatPrice(row.price)} €
                                </StyledTableCell>
                            </StyledTableRow>
                            : <></>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}

export default TourPrice