import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import TourApi from "../../../../Api/TourApi";
import useDimensions from "react-cool-dimensions";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import ResizeBox from "../../../../Components/ResizeBox";
import ResponsiveImage from "../../../../Components/ResponsiveImage";
import "../../../../Config/ConfigI18n.tsx";
import {useTranslation} from "react-i18next";
import MgmtPageTitle from "../../Components/MgmtPageTitle";
import MgmtPageActions from "../../Components/MgmtPageActions";

const TourOverview = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const [tours, setTours] = React.useState([TourApi.TourDefinition]);
    const [changed, setChanged] = React.useState(false)

    const {observe} = useDimensions({
        onResize: ({height}) => {
            if (height !== targetHeight) {
                setTargetHeight(height)
            }
        },
    });
    const [targetHeight, setTargetHeight] = useState(0)

    useEffect(() => {
        TourApi.GetTours().then((data: any) => {
            if (data) {
                setTours(data.payload);
            }
            setChanged(false)
        })
    }, [changed]);

    const onRowClick = (id: number) => {
        navigate("/mgmt/tour/edit/" + id, {replace: true})
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "cardPhoto",
            headerName: t("mgmtTourOverview.image"),
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return params.value ? <ResponsiveImage src={params.value.thumb} sx={{height: "50px"}}/> : <></>
            }
        },
        {
            field: 'code',
            headerName: t("mgmtTourOverview.code"),
            flex: 0.5,
            headerClassName: 'tableHeader',
            align: "left",
            headerAlign: "left",
        },
        {
            field: 'name',
            headerName: t("mgmtTourOverview.name"),
            flex: 1,
            headerClassName: 'tableHeader',
        },
        {
            field: 'description',
            headerName: t("mgmtTourOverview.description"),
            flex: 2,
            headerClassName: 'tableHeader',
        },
        {
            field: 'route',
            headerName: t("mgmtTourOverview.slug"),
            flex: 2,
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return params.value ? params.value.slug : ""
            }
        },
        {
            field: "active",
            headerName: t("mgmtTourOverview.active"),
            align: "center",
            headerAlign: "center",
            flex: 0.5,
            headerClassName: "tableHeader",
            renderCell: (params) => {
                return params.value ? t("generics.yes") : t("generics.no")
            }
        },
    ];

    const actions = [{label: "mgmtTourOverview.addATour", onClick: () => navigate("/mgmt/tour/edit", {replace: true})}]

    return <div ref={observe} style={{width: "100%"}}>
        <ResizeBox.Box>
            <MgmtPageTitle label={"mgmtSkeleton.tourManagement"} icon={"card_travel"}/>
            <MgmtPageActions actions={actions}/>

            <DataGrid
                sx={{height: ResizeBox.Height}}
                autoPageSize
                rows={tours}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 20, 30]}
                onRowSelectionModelChange={(i: any) => {
                    onRowClick(i)
                }}
                rowHeight={39}
                columnHeaderHeight={39}
            />
        </ResizeBox.Box>
    </div>
}

export default TourOverview