import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React from "react";

interface Props {
    src: string
    name: string
    open: any
    close: any
}

const FullSizeImage = (props: Props) => {
    return <Dialog
        open={props.open}
        maxWidth={"xl"}
        onClose={props.close}>
        <DialogTitle sx={{padding: "10px 12px"}}>
            {props.name}
            <IconButton
                aria-label="close"
                onClick={props.close}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}>
                <CloseRoundedIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{padding: "10px 12px"}}>
            <img alt="Full size preview" src={props.src} style={{maxHeight: "80vh", maxWidth: "100%"}}/>
        </DialogContent>
    </Dialog>
}

export default FullSizeImage