import React, {useEffect, useState} from "react";
import API from "../../../../../Api/Api";
import {Autocomplete, Box, TextField} from "@mui/material";
import {TFunction} from "i18next";

interface Props {
    t: TFunction
    onChange: any
    value: [{ id: number, name: string, abbreviation2: string }]
}

const SelectCountry = (props: Props) => {
    const t = props.t
    const [options, setOptions] = useState([])
    const [loaded, setLoaded] = useState(false)
    const getValue =  () => {
        return props.value && props.value.length > 0 ? props.value.map((d) => {
            return {id: d.id, name: d.name, code: d.abbreviation2}
        }) : []
    }

    useEffect(() => {
        if (!loaded) {
            API.Request('/country').then((d: any) => {
                const countries = d.payload.map((c: any) => {
                    return {
                        name: c.name,
                        id: c.id,
                        code: c.abbreviation2
                    }
                })
                setOptions(countries)
                setLoaded(true)
            })
        }
    }, [options, setOptions, loaded, setLoaded])

    return <Autocomplete
        fullWidth
        multiple
        id="tour-country"
        options={options}
        value={getValue()}
        getOptionKey={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="standard"
                label={t("generics.countries")}
            />
        )}
        renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                />
                {option.name}
            </Box>
        )}
        onChange={(_, d) => props.onChange("tourCountry", d)}
    />

}

export default SelectCountry