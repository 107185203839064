import React, {useEffect, useState} from "react";
import API from "../../../../../Api/Api";
import {
    Autocomplete, Button,
    Chip,
    createFilterOptions,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField, Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';

interface Props {
    onChange: any
    value: [{ id: number, name: string, inputValue?: string }]
}

interface Tag {
    name: string
    id: number
    inputValue?: string
}

const SelectTag = (props: Props) => {

    const HelperTextAddTag: string = "Click to Add: "
    const rawTag = {
        name: "",
        id: 0,
        used: 0,
    }

    const [options, setOptions] = useState([])
    const [tags, setTags] = useState([rawTag])
    const [loaded, setLoaded] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [toDelete, setToDelete] = useState(rawTag)
    const filter = createFilterOptions<Tag>();

    const getValue = () => {
        return props.value && props.value.length > 0 ? props.value.map((d) => {
            return {id: d.id, name: d.name}
        }) : []
    }

    useEffect(() => {
        if (!loaded) {
            API.Request('/tour/tag').then((d: any) => {
                const tag = d.payload.map((d: any) => {
                    return {
                        name: d.name,
                        id: d.id,
                        used: d.used
                    }
                })
                setOptions(tag)
                setTags(d.payload)
                setLoaded(true)
            })
        }
    }, [options, setOptions, loaded, setLoaded, tags, setTags])

    const addTag = (tag: any) => {
        const options = getValue()
        const body = {name: tag}
        API.Persist(0, "/tour/tag", body).then((d: any) => {
            options.push({id: d.payload.id, name: d.payload.name})
            props.onChange("tourTag", options)
            setLoaded(false)
        })
    }

    const onDelete = (tag: React.SetStateAction<{ name: string; id: number; used: number; }>) => {
        setToDelete(tag)
        setShowConfirm(true)
    }

    const onConfirmOk = () => {
        API.Request("/tour/tag/" + toDelete.id, "DELETE").then(() => {
            // remove from values
            const options = getValue().filter((e) => e.id !== toDelete.id)
            props.onChange("tourTag", options.map((d) => {
                return {id: d.id, name: d.name}
            }))
            setToDelete(rawTag)
            setShowConfirm(false)
            setLoaded(false)
        })
    }


    const CustomChip = (props: any) => {
        const data = tags.find((d) => d.id === props.id)
        return <Chip
            sx={{
                "& .MuiChip-icon": {
                    cursor: "pointer",
                    marginLeft: 1,
                    fontSize: "1.1rem"
                },
                "& .MuiChip-deleteIcon": {
                    order: 2 // since this is greater than an order of 1, it goes after the icon
                }
            }}
            {...props}
            color={getColor(data ? data.used : 0)}
            icon={data ? data.used === 0 ?
                    <DeleteIcon onClick={() => onDelete(data)}/>
                    : <LockIcon onClick={() => onDelete(data)}/>
                : <></>}
        />
    }

    const getColor = (count: number) => {
        switch (true) {
            case (count > 0) :
                return "primary"
            default:
                return "default"
        }
    }

    return <>
        <Dialog
            open={showConfirm}
        >
            <DialogTitle style={{cursor: 'move'}}>
                Delete Tag "{toDelete.name}"
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="body2"> Really delete TAG "{toDelete.name}"?</Typography>
                    {toDelete.used > 0 ?
                        <Typography variant="body2" mt={1}>
                            Used {toDelete.used} times as a reference in other tours. If you delete this tag,
                            all references in other tours are also deleted.
                        </Typography> : ""
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => {
                    setToDelete(rawTag)
                    setShowConfirm(false)
                }}>
                    Cancel
                </Button>
                <Button color="warning" onClick={onConfirmOk}>Delete</Button>
            </DialogActions>
        </Dialog>

        <Autocomplete
            fullWidth
            multiple
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const {inputValue} = params;
                // Suggest the creation of a new tag
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        name: HelperTextAddTag + inputValue,
                        id: 0
                    });
                }
                return filtered;
            }}
            id="tour-tag"
            options={options}
            value={getValue()}
            getOptionKey={(option: any) => option.id}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="Tags"
                />
            )}
            onChange={(_, d) => {
                const newTag = d.find((item) => item.id === 0)
                if (newTag) {
                    addTag(newTag.name.substring(HelperTextAddTag.length))
                }
                props.onChange("tourTag", d)
            }}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                    <CustomChip {...getTagProps({index})}
                                label={option ? option.name : ""}
                                id={option ? option.id : ""}
                    />
                ));
            }}
        />
    </>
}

export default SelectTag

//onAddItem={(_, d) => addTag(d.value)}