import {List, ListItem, ListItemIcon, Stack, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";


interface Props {
    text: any
    addons: any
}

const TourTextList = (props: Props) => {
    const {text, addons} = props

    return <Stack id="infos" mt={0} p={0}>
        <List>
            {text ? text.map((text: any, index: number) => {
                return <ListItem key={index} sx={{pl: "5px"}}>
                    <ListItemIcon sx={{
                        minWidth: "30px",
                    }}>
                        <InfoOutlinedIcon fontSize="small"/>
                    </ListItemIcon>
                    <Typography variant={"body1"}>{text.text}</Typography>
                </ListItem>
            }) : ""}
            {addons ? addons.map((item: any, index: number) => {
                return <ListItem key={index} sx={{pl: "5px"}}>
                    <ListItemIcon sx={{
                        minWidth: "30px",
                    }}>
                        <InfoOutlinedIcon fontSize="small"/>
                    </ListItemIcon>
                    <Typography variant={"body1"}> {item.item}</Typography>
                </ListItem>
            }) : ""}
        </List>
    </Stack>
}

export default TourTextList