import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "./de/translation.json";

i18next.use(initReactI18next).init({
    lng: "de", // as soon as we change to a language detector, do not define the lng option here
    debug: true,
    resources: {
        de: {
            translation,
        },
    },
});