import React, {useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import AppHeader from "../../AppHeader";
import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Toolbar,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import HikingOutlinedIcon from '@mui/icons-material/HikingOutlined';
import FollowTheSignsOutlinedIcon from '@mui/icons-material/FollowTheSignsOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';

import "../../Config/ConfigI18n.tsx";
import {useTranslation} from "react-i18next";

interface MenuItem {
    name: string,
    link: string
    icon: any
}

const ManagementSkeleton = () => {

    const {t} = useTranslation();

    const [active, setActive] = useState("")
    const navigate = useNavigate();

    const itemsUser: MenuItem[] = [
        {name: "mgmtSkeleton.user", link: "/mgmt/user", icon: <PeopleAltOutlinedIcon/>},
    ]

    const itemsTour: MenuItem[] = [
        {name: "mgmtSkeleton.tour", link: "/mgmt/tour", icon: <CardTravelOutlinedIcon/>},
        {name: "mgmtSkeleton.tourGroup", link: "/mgmt/tour/group", icon: <HolidayVillageOutlinedIcon/>},
        {name: "mgmtSkeleton.textModule", link: "/mgmt/textModules", icon: <TextSnippetOutlinedIcon/>},
    ]

    const itemsContent: MenuItem[] = [
        {name: "mgmtSkeleton.photo", link: "/mgmt/photo", icon: <InsertPhotoOutlinedIcon/>},
        {name: "mgmtSkeleton.photoSet", link: "/mgmt/photoSet", icon: <PhotoLibraryOutlinedIcon/>},
    ]

    const itemsWanderbar: MenuItem[] = [
        {name: "mgmtSkeleton.tour", link: "#", icon: <HikingOutlinedIcon/>},
        {name: "mgmtSkeleton.guides", link: "#", icon: <FollowTheSignsOutlinedIcon/>},
    ]

    const renderItems = (title: string, items: MenuItem[]) => {
        return <nav aria-label="management">
            <List sx={{
                width: "180px"
            }}>
                <ListSubheader sx={{
                    lineHeight: '20px',
                    mt: 1
                }}
                >{t(title)}</ListSubheader>
                {items.map((i: MenuItem) => {
                    return <ListItem dense key={i.name} sx={{p: 0.1}}>
                        <ListItemButton
                            onClick={() => {
                                setActive(i.name)
                                navigate(i.link)
                            }}
                            selected={active === i.name}
                        >
                            <ListItemIcon sx={{
                                minWidth: "30px"
                            }}>
                                {i.icon}
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{fontSize: "0.8rem"}} primary={t(i.name)}/>
                        </ListItemButton>
                    </ListItem>
                })}
            </List>
        </nav>
    }

    return <>
        <Box sx={{display: 'flex'}}>
            <AppHeader/>
            <Drawer
                variant="permanent"
                open={true}
                sx={{
                    width: '180px',
                    flexShrink: 0,
                }}
            >
                <Toolbar style={{minHeight:"74px"}}/>
                {renderItems("mgmtSkeleton.user", itemsUser)}
                {renderItems("mgmtSkeleton.tourManagement", itemsTour)}
                {renderItems("mgmtSkeleton.content", itemsContent)}
                {renderItems("mgmtSkeleton.wanderbar", itemsWanderbar)}
            </Drawer>
            <Box component="main"
                 sx={{
                     flexGrow: 1,
                     p: 2,
                     width: `calc(100% - 180px)`,
                     mt: "70px"
                 }}
            >
                <Outlet/>
            </Box>
        </Box>
        <AppBar
            position="fixed"
            style={{background: "gray"}}
            sx={{
                top: 'auto',
                bottom: 0,
                p: 0.5,
                textAlign: 'center',
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
        >
            Silvertip Tours GmbH (c) 2023, all rights reserved. Made with love by Silvertip IT-Solutions GmbH
        </AppBar>
    </>
}

export default ManagementSkeleton