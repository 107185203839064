import React from "react"
import {Stack, TextField} from "@mui/material";
import {TFunction} from "i18next";

interface Props {
    t: TFunction
    description: any
    onChange: any
}

const TourEditDescription = (props: Props) => {
    const t = props.t
    const {description, onChange} = props
    return <Stack direction="row" mt={2}>
        <TextField
            id="description"
            label={t("generics.description")}
            variant="filled"
            multiline
            value={description ? description : ""}
            placeholder="Description of the tour"
            name="description"
            fullWidth
            rows={15}
            onChange={(event) => onChange("description", event.target.value)}
        />
    </Stack>
}

export default TourEditDescription