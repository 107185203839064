import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField, Typography
} from "@mui/material";
import React, {useState} from "react";
import {jwtDecode} from "jwt-decode";
import ConfigGeneral from "../Config/ConfigGeneral";

import "../Config/ConfigI18n.tsx";
import { useTranslation } from "react-i18next";

interface Props {
    open: any
    close: any
    onChange: any
}

const Login = (props: Props) => {

    const { t } = useTranslation();

    const [err, setErr] = React.useState(false)
    const [jwt, setJWT] = useState("")

    const delay = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const doLogin = (data: any) => {
        fetch( ConfigGeneral.API_URL+'/iam/login', {
            method: "POST",
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                const token = data.payload.jwtToken
                setJWT(token)
                setErr(false)
                delay(1000).then(() => {
                    props.onChange(token)
                })
            })
            .catch(() => {
                setErr(true)
            });
    }

    const TokenInfo = () => {
        if (!jwt) return <></>
        const decodedToken = jwtDecode(jwt);
        return <Stack gap={1}>
            <Typography variant="subtitle2">
                Expiration: {decodedToken.exp}
            </Typography>
        </Stack>
    }

    return <Dialog
        open={props.open}
        //onClose={()=> props.close())}
        sx={{
            backdropFilter: "blur(15px)",
        }}
        PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries((formData as any).entries())
                doLogin(formJson)
            },
        }}
    >
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {t("login.greating")}
            </DialogContentText>
            <TextField
                autoFocus
                error={err}
                required
                margin="dense"
                id="login"
                name="login"
                label={t("login.user")}
                type="text"
                fullWidth
                variant="standard"
            />
            <TextField
                required
                error={err}
                margin="dense"
                id="password"
                name="password"
                label={t("login.password")}
                type="password"
                fullWidth
                variant="standard"
            />
            <TokenInfo/>
        </DialogContent>
        <DialogActions>
            {/*<Button onClick={props.close}>Cancel</Button>*/}
            <Button type="submit">{t("login.login")}</Button>
        </DialogActions>
        <div style={{fontSize: "0.5em"}}>
            <Typography align="center" fontSize="x-small">Version: {ConfigGeneral.VERSION}</Typography>
        </div>
    </Dialog>
}

export default Login