import React, {useEffect, useState} from "react";
import API from "../../../../../Api/Api";
import {Autocomplete, TextField} from "@mui/material";
import {TFunction} from "i18next";

interface Props {
    t:TFunction
    onChange: any
    value: [{ id: number, name: string }]
}

const SelectLanguage = (props: Props) => {
    const t= props.t
    const [options, setOptions] = useState([])
    const [loaded, setLoaded] = useState(false)
    const getValue = () => {
        return props.value && props.value.length > 0 ? props.value : undefined
    }

    useEffect(() => {
        if (!loaded) {
            API.Request('/tour/language').then((d: any) => {
                const language = d.payload.map((l: any) => {
                    return {
                        name: l.name,
                        id: l.id,
                    }
                })
                setOptions(language)
                setLoaded(true)
            })
        }
    }, [options, setOptions, loaded, setLoaded])

    return <Autocomplete
        fullWidth
        multiple
        id="tour-language"
        options={options}
        value={getValue()}
        getOptionKey={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="standard"
                label={t("generics.languages")}
            />
        )}
        onChange={(_, d) => props.onChange("tourLanguage", d)}
    />

}

export default SelectLanguage