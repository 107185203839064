import React from "react"
import {Button, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import AddBox from "@mui/icons-material/AddBox";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

interface MgmtAction {
    label: string
    onClick: any
    disabled?: boolean
    icon?: string
}

interface Props {
    actions: MgmtAction[]
    style?: any
}

const MgmtPageActions = (props: Props) => {

    const {actions} = props
    const {t} = useTranslation();

    const getIcon = (i:string|undefined) => {
        switch (i){
            case undefined: {
                return <AddBox/>
            }
            case "close" : {
                return <DisabledByDefaultIcon color="warning"/>
            }
            default : {
                return <HelpCenterIcon/>
            }
        }
    }
    return <Stack direction="row" alignItems="center" gap={1} paddingBottom="0.5em" style={props.style}>
        {actions.map((a,i) => {
            return <Button id={a.label} key={i} size="small" onClick={a.onClick} disabled={a.disabled} startIcon={getIcon(a.icon)}>
                {t(a.label)}
            </Button>
        })}
    </Stack>
}

export default MgmtPageActions