import React, {useState} from "react";
import styles from "./PhotoBar.module.css";
import ProgressiveImage from "../../../Components/ProgressiveImage";
import {Box, Container, ImageList, ImageListItem, Modal} from "@mui/material";
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface Props {
    photos: any
    size?: any
    type?: string
}

const PhotoBar = (props: Props) => {

    const [showFullscreen, setShowFullscreen] = useState(false)
    const [selected, setSelected] = useState(-1)

    console.log("photos: " + JSON.stringify(props.photos))

    if (!props.photos) return <></>

    const photos = () => {
        let count = 1
        const length = props.photos.length
        return props.photos.map((photo: any) => {
            let rows, cols = 1
            switch (count) {
                case 1: {
                    cols = 2
                    rows = 2
                    break
                }
                case 4: {
                    cols = 2
                    break
                }
                case 5: {
                    cols = 2
                    rows = length >= 8 ? 1 : 2
                    break
                }
                case 6: {
                    cols = length >= 8 ? 1 : 2
                    rows = length >= 8 ? 1 : 2
                    break
                }
                case 8: {
                    cols = 2
                    rows = 2
                    count = 1
                    break
                }
            }
            count++
            return {cols: cols, rows: rows, ...photo}
        })
    }

    const switchPhoto = (direction: string) => {
        switch (direction) {
            case "up": {
                if (selected === props.photos.length - 1) {
                    setSelected(0)
                } else {
                    setSelected(selected + 1)
                }
                break
            }
            case "down": {
                if (selected === 0) {
                    setSelected(props.photos.length - 1)
                } else {
                    setSelected(selected - 1)
                }
                break
            }
        }
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "rgb(102,102,102)",
    };

    const RenderFullScreen = () => {
        return <Modal
            open={showFullscreen}
            onClose={() => setShowFullscreen(false)}
            aria-describedby="modal-modal-photo"
            sx={{
                background: "rgb(102,102,102,1)",
            }}
            hideBackdrop={true}
        >
            <Box sx={style}>
                {props.photos[selected] ?
                    <div id="modal-modal-photo">
                        <ProgressiveImage
                            src={props.photos[selected].link}
                            className={styles.image}
                        />
                        <ArrowCircleRightRoundedIcon
                            fontSize="large"
                            className={styles.arrowRight}
                            onClick={() => switchPhoto("up")}/>
                        <ArrowCircleLeftRoundedIcon
                            fontSize="large"
                            className={styles.arrowLeft}
                            onClick={() => switchPhoto("down")}/>
                        <CloseRoundedIcon
                            fontSize="large"
                            className={styles.arrowClose}
                            onClick={() => setShowFullscreen(false)}/>
                    </div>
                    : ""}
            </Box>
        </Modal>
    }

    return <>
        {props.type === "bar" ?
            <ImageList
                sx={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: "repeat(auto-fit, minmax(380px,380px)) !important",
                    gridAutoColumns: "minmax(380px, 380px)",
                    background: "rgb(102,102,102)",
                    p: .5
                }}
            >
                {props.photos.map((image: any, index: number) => (
                    <ImageListItem>
                        <img alt={image.name}
                             style={{
                                 borderRadius: "5px",
                             }}
                             key={index}
                             src={image.link}
                             onClick={() => {
                                 setSelected(index)
                                 setShowFullscreen(true)
                             }}/>
                    </ImageListItem>
                ))}
            </ImageList>
            :
            <Container>
                <ImageList rowHeight={280} cols={4}>
                    {photos().map((image: any, index: number) => {
                        return <ImageListItem key={index}>
                            {/*<img alt={image.name}*/}
                            {/*     style={{*/}
                            {/*         borderRadius: "5px",*/}
                            {/*         objectFit: "cover"*/}
                            {/*     }}*/}
                            {/*     key={index}*/}
                            {/*     src={image.thumb}*/}
                            {/*     onClick={() => {*/}
                            {/*         setSelected(index)*/}
                            {/*         setShowFullscreen(true)*/}
                            {/*     }}*/}
                            {/*/>*/}
                            <div style={{
                                width: "280px",
                                height: "100%",
                                backgroundImage: `url(${image.link}) `,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                borderRadius: "5px"
                            }}
                                 onClick={() => {
                                     setSelected(index)
                                     setShowFullscreen(true)
                                 }
                                 }>
                            </div>
                        </ImageListItem>
                    })}
                </ImageList>
            </Container>
        }
        <RenderFullScreen/>
    </>
}

export default PhotoBar