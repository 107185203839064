import React from "react";
import CardTourGroup from "./Tour/CardTourGroup";
import {Box, Container, Fade, Paper, Stack, styled, Typography, useScrollTrigger} from "@mui/material";
import ResponsiveImage from "../Components/ResponsiveImage";

import "../Config/ConfigI18n.tsx";
import { useTranslation } from "react-i18next";

const Home = () => {
    const { t } = useTranslation();

    const AdPaper = styled(Paper)(({theme}) => ({
        padding: theme.spacing(8),
        textAlign: 'center',
        justifyItems: 'center',
        verticalAlign: 'middle',
        marginTop: '3rem',
        background: 'rgb(69,147,198,0.3)'
    }));

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 10,
    });

    return <>
        <Fade in={true} timeout={1000}>
            <Box sx={{display: 'block'}}>
                <ResponsiveImage src="/assets/fullsize-header-home.png" sx={{height: "400px"}}/>
            </Box>
        </Fade>
        <Container>
            <Stack id='headline' sx={{mt: 4}}>
                <Typography variant='h4'>
                    {t("home.headline")}
                </Typography>
            </Stack>
            <Stack id='greating' direction='row' sx={{mt: 4}} flex={1} gap={3}>
                <Stack>
                    <Typography variant='body1' sx={{width: '65vh', fontSize: '1.2rem'}} textAlign='justify'>
                        Unser Angebot richtet sich an vielseitig interessierte und aktive Menschen. Bei unseren
                        Touren kombinieren wir sportliche Aktivitäten (Biken, Wandern, Skitouren u.v.m.) mit
                        kulturellen Begegnungen und einer Portion süßem Nichts-Tun. Sammeln Sie neue Erfahrungen, lernen
                        Sie
                        Gleichgesinnte kennen, und gehen Sie auf Tuchfühlung mit Land und Leuten.
                        Doch bevor es los geht, nehmen wir uns Zeit für Ihre individuelle Beratung, denn wir
                        sind überzeugt, dass dies die beste Grundlage für die erfolgreiche Auswahl Ihres nächsten
                        Abenteuers
                        ist.
                    </Typography>
                    <Typography variant='body1' sx={{fontWeight: 600, pt: 4}}>
                        Ihr Team von Silvertip Tours
                    </Typography>
                </Stack>
                <div style={{flex: 1, textAlign: "center"}}>
                    <div className="headingTitle">Lust auf Abenteuer?</div>
                    <div className="headingSubTitle">Hier geht es zu Deinem nächsten Urlaub.</div>
                </div>
            </Stack>
        </Container>

        <Fade in={trigger} timeout={3000}>
            <AdPaper square={false} elevation={0}>
                <div className="headingSubTitle">Wir feiern 10-jähriges Jubiläum.</div>
                <div className="headingSubTitle">Silvertip Tours wird dieses Jahr 10 Jahre alt.</div>
            </AdPaper>
        </Fade>

        <Container>
            <Stack id='tours and groups' sx={{mt: 7}}>
                <CardTourGroup/>
            </Stack>
        </Container>
    </>
}

export default Home