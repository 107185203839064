import React, {useEffect, useState} from "react";
import ResizeBox from "../../../Components/ResizeBox";
import PhotoApi from "../../../Api/PhotoApi";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import ResponsiveImage from "../../../Components/ResponsiveImage";
import {useNavigate} from "react-router-dom";
import MgmtPageTitle from "../Components/MgmtPageTitle";

const PhotoSet = () => {

    const navigate = useNavigate();

    const [photoSets, setPhotoSets] = useState([])
    useEffect(() => {
        PhotoApi.GetPhotoSets().then((data: any) => {
            if (data) {
                setPhotoSets(data.payload.map((p: any) => {
                    return {...p, count: p.photo.length}
                }));
            }
        })
    }, []);

    const onRowClick = (id: number) => {
        navigate("/mgmt/photoSet/edit/" + id, {replace: true})
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "photo",
            headerName: "Image",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return params.value.length>0 && params.value[0].thumb ?
                    <ResponsiveImage src={params.value[0].thumb} sx={{height: "50px"}}/> : ""
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5,
            headerClassName: 'tableHeader',
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            headerClassName: 'tableHeader',
        },
        {
            field: 'count',
            headerName: '#Photos',
            headerClassName: 'tableHeader',
        },
    ]

    return <ResizeBox.Box>
        <MgmtPageTitle label={"photo.photoSets"} icon={"photo_library"}/>
        <div className='pd1'/>
        <DataGrid
            sx={{
                height: ResizeBox.Height,
                ".MuiDataGrid-columnHeaderTitleContainer": {
                    backgroundColor: "aliceblue",
                },
            }}
            autoPageSize
            rows={photoSets}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
            rowHeight={39}
            columnHeaderHeight={39}
            onRowSelectionModelChange={(i: any) => {
                onRowClick(i)
            }}
        />
    </ResizeBox.Box>

}

export default PhotoSet