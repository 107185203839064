import React, {useEffect, useState} from "react"
import TourApi from "../../../../../Api/TourApi";
import {Autocomplete, TextField} from "@mui/material";


interface Props {
    t:any
    onChange: any
    value: [{ id: number, name: string }]
}

const SelectTourGroup = (props: Props) => {
    const t = props.t
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (loading)
            TourApi.GetGroups().then((d: any) => {
                setOptions(d.payload)
                setLoading(false)
            })
    }, [options, loading])


    return <Autocomplete
        fullWidth
        multiple
        id="tour-groups"
        options={options}
        value={props.value && props.value.length > 0 ? props.value : []}
        getOptionKey={(option: any) => option.name}
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="standard"
                label={t("mgmtTourEdit.tourGroup")}
            />
        )}
        onChange={(_, d) => props.onChange("tourGroup", d)}
    />
}

export default SelectTourGroup