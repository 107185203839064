import React from "react"
import {Typography} from "@mui/material";
import ProgramTimeline from "../../Components/ProgramTimeline";


interface Props {
    program: any
}

const TourProgram = (props: Props) => {
    return props.program ?
        <>
            <Typography variant='h5'>
                Reiseverlauf
            </Typography>
            <ProgramTimeline program={props.program}/>
        </>
        :
        <></>
}

export default TourProgram