import React, {useCallback, useEffect, useState} from "react"
import {useImmer} from "use-immer";
import API from "../../../../Api/Api";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, IconButton, InputLabel,
    MenuItem, Select,
    Stack, TextField,
    Typography
} from "@mui/material";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import Utils from "../../../../Common/Utils";
import {TFunction} from "i18next";

interface Props {
    t:TFunction
    onChange: any
    onChangeText: any
    text: any
    filter: string
    values: any
    title?: string
}

const TourEditInfo = (props: Props) => {

    const t=props.t
    interface TextModule {
        id: number | null
        type: string
        name: string
        text: string
    }

    interface Item {
        id: number | null
        name: string
        text: string
        order: number
        toDelete: boolean
        actionID: number | null
    }

    interface Options {
        key: number
        value: number
        text: string
        name: string
    }

    const [items, setItems] = useImmer<Item[]>([])
    const [text, setText] = useState<TextModule[]>([])
    const [options, setOptions] = useState<Options[]>([])
    const [textModules, setTextModules] = useState<TextModule[]>([])
    const [loading, setLoading] = useState(true)
    const [clean, setClean] = useState(false)
    const [showDialogFreeText, setShowDialogFreeText] = useState(false)
    const [showDialogTextModule, setShowDialogTextModule] = useState(false)

    const updateOptions = useCallback((modules: any, values: TextModule[]) => {
        const newOptions: Options[] = []
        modules.forEach((d: any) => {
            if (values && !values.find((t: any) => {
                return d.id === t.id
            })) {
                newOptions.push({
                    key: d.id,
                    value: d.id,
                    text: d.text,
                    name: d.name,
                })
            } else if (!values) {
                newOptions.push({
                    key: d.id,
                    value: d.id,
                    text: d.text,
                    name: d.name,
                })
            }
        })
        if (newOptions.length > 0) {
            setOptions(newOptions)
        }
    }, [])

    // Load initial values
    useEffect(() => {
        if (loading) {
            const newItems = props.values ? props.values.map((item: Item) => {
                return {
                    ...item,
                    actionID: item.id
                }
            }) : null
            setItems(newItems)
            if (props.text) {
                setText(props.text)
            }
            API.Request('/tour/text').then((d: any) => {
                const modules = d.payload.filter((d: any) => d.type === props.filter || d.type === "universal")
                updateOptions(modules, props.text)
                setTextModules(modules)
            })
            setLoading(false)
        }
    }, [loading, props, setItems, updateOptions])

    useEffect(() => {
        if (clean) {
            const newItems = items.filter((i) => {
                return i.name.length > 0 || (i.toDelete && i.id)
            })
            props.onChange(newItems)
            setClean(false)
        }
    }, [clean, items, props])

    const saveNewItem = (name: string, text: string) => {
        if (!name || !text) return
        setShowDialogFreeText(false)
        if (!items) {
            setItems([{
                name: name,
                text: text,
                order: 0,
                toDelete: false,
                id: null,
                actionID: 1
            }])
        } else {
            setItems((draft) => {
                    draft.push({
                        name: name,
                        text: text,
                        order: items.length,
                        toDelete: false,
                        id: null,
                        actionID: items.length + 1
                    })
                }
            )
        }
        setClean(true)
    }

    const onSelect = (value: any) => {
        if (value === -1) return
        const draft = text.map((d) => {
            return d
        })
        if (draft.find((d) => {
            return d.id === value
        })) return
        const toAdd = textModules.find((m) => {
            return m.id === value
        })
        if (toAdd) draft.push(toAdd)
        setText(draft)
        const newOptions = options.filter((d) => {
            return d.value !== value
        })
        setOptions(newOptions)
        props.onChangeText(props.filter, draft)
        setShowDialogTextModule(false)
    }

    const onDeleteText = (id: number | null) => {
        if (!id) return
        const newText = text.filter((d) => {
            return d.id !== id
        })
        setText(newText)
        props.onChangeText(props.filter, newText)
        updateOptions(textModules, newText)
    }

    const onDeleteItem = (actionID: number) => {
        const removeNewItems = items.filter((item) => item.id || item.actionID !== actionID)
        const setToDelete = removeNewItems.map((item) => {
            if (item.id && item.actionID === actionID) {
                return {...item, toDelete: true}
            } else {
                return {...item}
            }
        })
        setItems(setToDelete)
        setClean(true)
    }

    const filterToDelete = (items: any) => {
        if (items) {
            return items.filter((i: any) => !i.toDelete)
        } else {
            return []
        }
    }

    const DialogFreeText = () => {
        return <Dialog
            open={showDialogFreeText}
            onClose={() => setShowDialogFreeText(false)}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData as any).entries())
                    saveNewItem(formJson.name, formJson.text)
                },
            }}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle>
                {t("mgmtTourEdit.addNewItem")}
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{flex: 1}}
                    multiline
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="name"
                    name="name"
                    label={t("mgmtTourEdit.headline")}
                    type="text"
                    variant="standard"
                />
                <TextField
                    sx={{flex: 1}}
                    multiline
                    rows={4}
                    fullWidth
                    margin="dense"
                    id="text"
                    name="text"
                    label={t("generics.description")}
                    type="text"
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowDialogFreeText(false)}>{t("generics.cancel")}</Button>
                <Button type="submit">{t("generics.save")}</Button>
            </DialogActions>
        </Dialog>
    }

    const DialogTextModule = () => {
        return <Dialog
            open={showDialogTextModule}
            onClose={() => setShowDialogTextModule(false)}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle>
                {t("mgmtTourEdit.addTextModule")}
            </DialogTitle>
            <DialogContent>
                <FormControl variant="standard" sx={{m: 1, width: "100%"}}>
                    <InputLabel>{t("mgmtTourEdit.textModule")}</InputLabel>
                    <Select
                        id="textModule"
                        name="textModule"
                        variant="standard"
                        value={""}
                        margin="dense"
                        onChange={(event) => onSelect(event.target.value)}
                        label="Type"
                        SelectDisplayProps={{
                            style: {display: 'flex', alignItems: 'center'},
                        }}
                    >
                        {options.map((option) => {
                            return <MenuItem value={option.value} key={option.key}>
                                <Typography variant="subtitle2">Name:</Typography>
                                <Typography variant="body1" ml={1}> {option.name}</Typography>
                                <Typography variant="subtitle2" ml={2}>Text:</Typography>
                                <Typography variant="body1" ml={1}> {option.text}</Typography>
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </DialogContent>
        </Dialog>
    }

    const RenderItem = ({item, nw}: any) => {
        return <Stack direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={1}>
            {item.actionID ?
                <EditNoteRoundedIcon sx={{pt:"1px"}} fontSize="small" color="disabled"/> :
                <TextSnippetRoundedIcon sx={{pt:"1px"}} fontSize="small" color="disabled"/>
            }
            <Typography variant={"body1"} sx={{fontWeight: 600, mr: 1, width: nw}}>
                {item.name}:
            </Typography>
            <Typography variant={"body1"} flex={1}>
                {item.text}
            </Typography>
            <IconButton onClick={() => {
                item.actionID ? onDeleteItem(item.actionID) : onDeleteText(item.id)
            }}>
                <DeleteOutlineRoundedIcon sx={{mt:"-6px"}} color="warning" fontSize="small"/>
            </IconButton>
        </Stack>
    }

    const RenderText = () => {
        const lines: any = [...filterToDelete(items), ...filterToDelete(text)]
        const nw = Utils.calcMaxWidthCharacters(lines, "name")
        const sorted = lines.sort((a: any, b: any) => {
            if (!a.name || !b.name) return 0
            return a.name.localeCompare(b.name)
        })
        return <Stack gap={1} p={0.5}>
            {sorted.map((item: any, index: number) => {
                return <RenderItem item={item} key={index} nw={nw}/>
            })}
        </Stack>
    }

    return <Stack mt={2}>
        <Stack direction="row">
            {options.length > 0 ?
                <Button onClick={() => setShowDialogTextModule(true)}>
                    {t("mgmtTourEdit.addTextModule")}
                </Button>
                : ""}
            <Button onClick={() => {
                setShowDialogFreeText(true)
            }}>
                {t("mgmtTourEdit.addText")}
            </Button>
        </Stack>
        <RenderText/>
        <DialogTextModule/>
        <DialogFreeText/>
    </Stack>
}

export default TourEditInfo