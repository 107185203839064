import React from "react"
import {Snackbar} from "@mui/material";
import {Alert} from "@mui/material";

interface MessageProps {
    severity?: string
    message?: string
    open: boolean
}

interface Props {
    open: any
    close: any
    message: MessageProps
}

const Message = (props: Props) => {

    const handleCloseMessage = (_?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        props.close(false);
    };

    const transformSeverity = (severity: string|undefined) => {
        switch (severity) {
            case "success" : {
                return "success"
            }
            case "warning" : {
                return "warning"
            }
            case "error" : {
                return "error"
            }
            default: {
                return "success"
            }
        }
    }
    return <Snackbar
        open={props.open}
        autoHideDuration={6000}
        onClose={handleCloseMessage}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
    >
        <Alert
            onClose={handleCloseMessage}
            severity={transformSeverity(props.message.severity)}
            sx={{width: '100%'}}
        >
            {props.message.message}
        </Alert>
    </Snackbar>
}

export default Message