import {Stack, Typography, Icon} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    label: string
    icon: string
    disableBorder?: boolean
}

const MgmtPageTitle = (props: Props) => {

    const {label, icon, disableBorder} = props
    const {t} = useTranslation();

    return <Stack direction="row" alignItems="center" gap={1} paddingBottom="0.5em"
                  style={!disableBorder ? {borderBottom: "lightgray solid 1px"} : {} }>
        <Icon baseClassName="material-icons-outlined" sx={{marginTop: "-2px"}}> {icon}</Icon>
        <Typography variant='h6'>{t(label)}</Typography>
    </Stack>

}

export default MgmtPageTitle
