import React from "react"
import {Box, Stack, TextField} from "@mui/material";
import Transform from "../../../../Common/Transform";
import {TFunction} from "i18next";

interface Props {
    t: TFunction
    tour: any
    onChange: any
}

const TourEditInternals = (props: Props) => {
    const {t, tour, onChange} = props

    return <Box
        component="form"
        noValidate
        autoComplete="off"
    >
        <Stack direction="row" gap={1}>
            <TextField
                autoFocus
                required
                fullWidth
                value={tour.route.slug ? tour.route.slug : tour.name ? Transform.Slugify(tour.name) : ""}
                margin="dense"
                id="slug"
                name="slug"
                label={t("mgmtTourEdit.tourSlug")}
                placeholder="URL Kürzel, wird dann an silvertip.at/ angehängt. z.B.: aus Peru wird www.silvertip.at/peru "
                helperText={t("mgmtTourEdit.helperSlug")}
                type="text"
                variant="standard"
                onChange={(event) => onChange("slug", event.target.value)}
            />
        </Stack>
        <Stack direction="row" gap={1}>
            <TextField
                id="internalNote"
                label={t("mgmtTourEdit.internalNote")}
                variant="filled"
                multiline
                value={tour.internalNote ? tour.internalNote : ""}
                placeholder="Internal Note"
                name="internalNote"
                sx={{mt: 2}}
                fullWidth
                rows={10}
                onChange={(event) => onChange("internalNote", event.target.value)}
            />
        </Stack>
    </Box>
}

export default TourEditInternals