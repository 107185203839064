import {Stack, Typography} from "@mui/material";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import React from "react";

interface Props {
    season: any
}

const TourSeason = (props: Props) => {
    const season = props.season
    const months = []
    let yearAround = season.yearAround ? "Diese Reise ist ganzjährig buchbar" : ""

    if (season.january) months.push("Januar")
    if (season.february) months.push("Februar")
    if (season.march) months.push("März")
    if (season.april) months.push("April")
    if (season.may) months.push("Mai")
    if (season.june) months.push("Juni")
    if (season.july) months.push("Juli")
    if (season.august) months.push("August")
    if (season.september) months.push("September")
    if (season.october) months.push("Oktober")
    if (season.november) months.push("November")
    if (season.december) months.push("Dezember")

    if (months.length > 0 && season.yearAround) {
        yearAround = yearAround + ", außer im " + months.join(", ")
    }

    return <Stack direction="row" mt={2} alignItems="center" p={0}>
        <WbSunnyOutlinedIcon fontSize="small" sx={{
            minWidth: "30px",
            pr: "5px"
        }}/>
        <Typography variant="body1">
            {yearAround ? yearAround : "Buchbar im " + months.join(", ")}
        </Typography>
    </Stack>

}

export default TourSeason