const Utils: any = {

    calcMaxWidthCharacters: (objects: any, field: string) => {
        let nw = 0
        objects.forEach((e: any): void => {
            if (e[field].length > nw) {
                nw = e[field].length
            }
        })
        return nw + "ch"
    },

    // makeId generates a random hash id with the provided length
    // Default length = 6
    makeId: (length?: number) => {
        const maxLength = length ? length : 6
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < maxLength) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }
}

export default Utils