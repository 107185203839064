import {
    Button,
    Paper,
    Stack, styled,
    Table,
    TableBody,
    TableCell, tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import Transform from "../../Common/Transform";

interface Props {
    dates: any

}

const TourGroupDates = (props: Props) => {

    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.body}`]: {
            fontSize: "1rem",
        },
    }));
    const StyledTableRow = styled(TableRow)(() => ({
        [`'&:last-child td, &:last-child th'`]: {
            border: 0,
        },
    }));

    return <TableContainer component={Paper} sx={{mt: 2}} elevation={0}>
        <Typography variant='h6'>Gruppentermine</Typography>
        <Typography variant="subtitle1" sx={{fontWeight: 600, mt: 2}}>Preis pro Person</Typography>
        <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
                <StyledTableRow>
                    <StyledTableCell align="left" sx={{padding: 1, width: "12ch"}}>Vom</StyledTableCell>
                    <StyledTableCell align="left" sx={{padding: 1, width: "12ch"}}>Bis</StyledTableCell>
                    <StyledTableCell align="center" sx={{padding: 1, width: "12ch"}}>Nächte</StyledTableCell>
                    <StyledTableCell align="left" sx={{padding: 1}}></StyledTableCell>
                    <StyledTableCell align="right" sx={{padding: 1}}></StyledTableCell>
                    <StyledTableCell align="right" sx={{padding: 1}}></StyledTableCell>
                </StyledTableRow>
            </TableHead>
            <TableBody>
                {props.dates.map((row: any) => (
                    <StyledTableRow key={row.id}>
                        <StyledTableCell align="left" sx={{padding: 1}}>
                            {row.start ? Transform.FormatDate(row.start) : ""}
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{padding: 1}}>
                            {row.end ? Transform.FormatDate(row.end) : ""}
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{padding: 1}}>
                            {row.nights ? row.nights : ""}
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{padding: 1}}>
                            {row.note ?
                                <Stack direction="row"><InfoOutlinedIcon
                                    fontSize="small"/>&nbsp;{row.note}
                                </Stack>
                                : ""}
                        </StyledTableCell>
                        <StyledTableCell align="right" sx={{padding: 1}}>
                            {Transform.FormatPrice(row.price)}
                        </StyledTableCell>
                        <StyledTableCell align="right" sx={{width: "10ch", padding: 1}}>
                            <Button variant="contained" disableElevation size="small" sx={{
                                backgroundColor:"rgb(69,147,198,0.8)",
                                '&:hover': {
                                    backgroundColor: "rgb(69,147,198,1)"
                                }
                            }}>
                                Anfragen
                            </Button>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>

}

export default TourGroupDates