import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useImmer} from "use-immer";
import TourApi from "../../../../Api/TourApi";
import Transform from "../../../../Common/Transform";
import ResizeBox from "../../../../Components/ResizeBox";
import {
    Stack,
    ClickAwayListener,
    Dialog,
    DialogTitle,
    DialogContent, DialogActions, DialogContentText, Modal, Box
} from "@mui/material";
import TourEditGeneral from "./TourEditGeneral";
import TourEditInternals from "./TourEditInternals";
import TourEditImpressions from "./TourEditImpressions";
import TourEditOptions from "./TourEditOptions";
import TourEditDates from "./TourEditDates";
import TourEditPrice from "./TourEditPrice"
import TourEditDescription from "./TourEditDescription";
import TourEditProgram from "./TourEditProgram";
import TourEditText from "./TourEditText";
import Message from "../../../../Components/Message";
import TourEditInfo from "./TourEditInfo";
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';

import '../../../../Config/ConfigI18n.tsx';
import {useTranslation} from "react-i18next";
import MgmtPageTitle from "../../Components/MgmtPageTitle";
import MgmtPageTabs from "../../Components/MgmtPageTabs.tsx";
import MgmtPageSaveClose from "../../Components/MgmtPageSaveClose.tsx";

const TourEdit = () => {

    const {t} = useTranslation();

    interface TextModule {
        id: number | null
        type: string
        name: string
        text: string
    }

    interface TourDefinition {
        id: number,
        route: {
            slug: string,
            route: string,
            pointTo: string,
            active: boolean
        },
        name: string,
        description: string,
        internalNote: string,
        active: boolean,
        promotion: boolean,
        private: boolean,
        code: string,
        headerPhoto: {
            id: number,
            name: string
        },
        cardPhoto: {
            id: number,
            name: string
        },
        startPlace: string
        finishPlace: string
        startPrice: number
        nights: number
        accommodation: string
        group: [{
            id: number
            name: string
        }]
        country: null
        language: [{
            id: number
        }]
        tag: [{
            id: number
        }]
        photo: any
        photoSet: number
        character: string
        guidedBy: string
        season: {
            id: number | undefined
            yearAround: boolean
            january: boolean
            february: boolean
            march: boolean
            april: boolean
            may: boolean
            june: boolean
            july: boolean
            august: boolean
            september: boolean
            october: boolean
            november: boolean
            december: boolean
        }
        program: any
        include: any
        exclude: any
        dates: []
        price: []
        dateAddon: any
        individualAddon: any
        textInclude: TextModule[]
        textExclude: TextModule[]
        textDate: TextModule[]
        textIndividual: TextModule[]
        textProgram: TextModule[]
        info: []
        textInfo: TextModule[]
    }

    interface MessageProps {
        severity?: string
        message?: string
        open: boolean
    }

    const emptyMessage: MessageProps = {
        severity: "success",
        message: "I'm ok",
        open: false
    }

    const navigate = useNavigate();
    const params = useParams()
    const [tour, setTour] = useImmer<TourDefinition>(TourApi.TourDefinition)
    const [load, setLoad] = useState(true)
    const [slug, setSlug] = useState(false)
    const [changed, setChanged] = useState(false)
    const [message, setMessage] = useState<MessageProps>(emptyMessage)
    const [openDialogUnsavedChanges, setDialogUnsavedChanges] = useState(false)
    const [showProgress, setsSowProgress] = useState(false)

    useEffect(() => {
        if (params.id && load) {
            if (!showProgress) setsSowProgress(true)
            TourApi.GetTourDetail(params.id).then((data: any) => {
                const payload = data.payload
                setTour({...payload})
                setLoad(false)
            }).catch((error: string) => {
                setMessage({
                    severity: "error",
                    message: `Error loading tour ${error}`,
                    open: true
                })
                setLoad(false)
            })
            setsSowProgress(false)
        }
    }, [load, params.id, setTour, showProgress])

    const onSetActive = (name: string, checked: boolean | undefined) => (
        setTour((draft) => {
            switch (name) {
                case "active": {
                    draft.active = checked ? checked : false
                    break
                }
                case "promotion": {
                    draft.promotion = checked ? checked : false
                    break
                }
                case "private": {
                    draft.private = checked ? checked : false
                    break
                }
                case "yearAround": {
                    draft.season.yearAround = checked ? checked : false
                    break
                }
            }
            setChanged(true)
        })
    )

    const onChange = (name: string, value: any) => {
        const onlyNumbers = /^\d+$/
        setTour((draft) => {
            switch (name) {
                case "name": {
                    draft.name = value
                    if (!slug) {
                        draft.route.slug = Transform.Slugify(value)
                    }
                    break
                }
                case "slug": {
                    setSlug(true)
                    draft.route.slug = value
                    break
                }
                case "code": {
                    draft.code = value
                    break
                }
                case "description": {
                    draft.description = value
                    break
                }
                case "startPlace": {
                    draft.startPlace = value
                    break
                }
                case "finishPlace": {
                    draft.finishPlace = value
                    break
                }
                case "nights": {
                    if (onlyNumbers.test(value)) draft.nights = Number(value)
                    break
                }
                case "accommodation" : {
                    draft.accommodation = value
                    break
                }
                case "internalNote" : {
                    draft.internalNote = value
                    break
                }
                case "character" : {
                    draft.character = value
                    break
                }
                case "startPrice" : {
                    console.log("changePrice: " + value)
                    draft.startPrice = Number(value)
                    break
                }
                case "guidedBy" : {
                    draft.guidedBy = value
                    break
                }
                case "tourGroup": {
                    draft.group = value
                    break
                }
                case "tourCountry": {
                    draft.country = value
                    break
                }
                case "tourLanguage": {
                    draft.language = value
                    break
                }
                case "tourTag": {
                    draft.tag = value
                    break
                }
            }
            setChanged(true)
        })
    }

    const onChangeSeason = (value: any) => {
        const newSeasons = {
            ...value,
            yearAround: tour.season.yearAround,
            id: tour.season.id ? tour.season.id : undefined
        }
        setTour((draft) => {
            draft.season = newSeasons
        })
        setChanged(true)
    }

    const onChangeProgram = (value: any) => {
        setTour((draft) => {
            draft.program = value
        })
        setChanged(true)
    }

    const onChangeDateAddon = (value: any) => {
        setTour((draft) => {
            draft.dateAddon = value
        })
        setChanged(true)
    }

    const onChangeIndividualAddon = (value: any) => {
        setTour((draft) => {
            draft.individualAddon = value
        })
        setChanged(true)
    }

    const onChangeInclude = (value: any) => {
        setTour((draft) => {
            draft.include = [...value]
        })
        setChanged(true)
    }

    const onChangeExclude = (value: any) => {
        setTour((draft) => {
            draft.exclude = value
        })
        setChanged(true)
    }

    const onChangeInfo = (value: any) => {
        setTour((draft) => {
            draft.info = value
        })
        setChanged(true)
    }

    const onChangeDates = (value: any) => {
        setTour((draft) => {
            draft.dates = value
        })
        setChanged(true)
    }

    const onChangeText = (filter: string, value: any) => {
        setTour((draft) => {
            switch (filter) {
                case "include" : {
                    draft.textInclude = value
                    break
                }
                case "exclude" : {
                    draft.textExclude = value
                    break
                }
                case "date" : {
                    draft.textDate = value
                    break
                }
                case "individual" : {
                    draft.textIndividual = value
                    break
                }
                case "program" : {
                    draft.textProgram = value
                    break
                }
                case "info" : {
                    draft.textInfo = value
                    break
                }
            }
        })
        setChanged(true)
    }

    const onChangePrice = (price: any) => {
        setTour((draft) => {
            draft.price = price
        })
        setChanged(true)
    }

    const onChangeHeaderFile = (files: any) => {
        setTour((draft) => {
            draft.headerPhoto = files[0]
        })
        setChanged(true)
    }

    const onChangeCardFile = (files: any) => {
        setTour((draft) => {
            draft.cardPhoto = files[0]
        })
        setChanged(true)
    }

    const onChangePhoto = (files: any) => {
        setTour((draft) => {
            draft.photo = files
        })
        setChanged(true)
    }

    const onChangePhotoSet = (photoSet: any) => {
        setTour((draft) => {
            draft.photoSet = photoSet
        })
        setChanged(true)
    }

    const delay = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const SaveProgress = () => {
        return <Modal
            open={showProgress}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
            <Box sx={{
                display: 'flex',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#C08AeA'),
                        animationDuration: '550ms',
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    size={120}
                    thickness={6}
                />
            </Box>
        </Modal>
    }

    const onSave = (close?: boolean) => {
        setsSowProgress(true)
        TourApi.PersistTour(tour).then(() => {
            if (close) {
                delay(500).then(() => {
                    setTour(TourApi.TourDefinition)
                    navigate("/mgmt/tour")
                })
            } else {
                setMessage({
                    severity: "success",
                    message: `Changes for tour "${tour.name}" saved successfully `,
                    open: true
                })
                setChanged(false)
                setLoad(true)
            }
        }).catch((error: string) => {
            setMessage({
                severity: "error",
                message: `Error saving changes ${error}`,
                open: true
            })
            setsSowProgress(false)
        })
    }

    const canSave = () => {
        return !!(tour.name &&
            tour.code &&
            tour.cardPhoto &&
            tour.headerPhoto);
    }

    const [tabsValue, setTabsValue] = React.useState("general")
    const handleTabsChange = (_: React.SyntheticEvent, id: string) => {
        setTabsValue(id);
    }

    const tabPanels = [
        {
            value: "general",
            element: <TourEditGeneral
                t={t}
                tour={tour}
                onChange={onChange}
                onSetActive={onSetActive}
                onChangeHeaderFile={onChangeHeaderFile}
                onChangeCardFile={onChangeCardFile}
            />
        },
        {
            value: "options",
            element: <TourEditOptions
                t={t}
                tour={tour}
                onChange={onChange}
                onSetActive={onSetActive}
                onChangeSeason={onChangeSeason}
            />
        },
        {
            value: "dates",
            element: <>
                <TourEditDates
                    t={t}
                    nights={tour.nights}
                    price={tour.startPrice}
                    dates={tour.dates}
                    onChangeDates={onChangeDates}
                />
                <div className={"pd1"}/>
                <TourEditPrice
                    t={t}
                    price={tour.price}
                    onChange={onChangePrice}
                    isGroup
                />
                <TourEditText
                    t={t}
                    values={tour.dateAddon}
                    onChange={onChangeDateAddon}
                    text={tour.textDate ? tour.textDate : ""}
                    filter={"date"}
                    onChangeText={onChangeText}
                />
            </>
        },
        {
            value: "individual",
            element: <>
                <TourEditPrice
                    t={t}
                    price={tour.price}
                    onChange={onChangePrice}
                />
                <div className={"pd1"}/>
                <TourEditPrice
                    t={t}
                    price={tour.price}
                    onChange={onChangePrice}
                    isOption
                />
                <TourEditText
                    t={t}
                    values={tour.individualAddon}
                    onChange={onChangeIndividualAddon}
                    text={tour.textIndividual ? tour.textIndividual : ""}
                    filter={"individual"}
                    onChangeText={onChangeText}
                />
            </>
        },
        {
            value: "impressions",
            element: <TourEditImpressions
                t={t}
                files={tour.photo}
                photoSet={tour.photoSet}
                onChange={onChangePhoto}
                onChangePhotoSet={onChangePhotoSet}
            />
        },
        {
            value: "description",
            element: <TourEditDescription
                t={t}
                description={tour.description}
                onChange={onChange}
            />
        },
        {
            value: "program",
            element: <TourEditProgram
                t={t}
                onChange={onChangeProgram}
                value={tour.program}
            />
        },
        {
            value: "services",
            element: <Stack mt={2}>
                <TourEditText
                    t={t}
                    values={tour.include}
                    onChange={onChangeInclude}
                    title={t("generics.includes") + ":"}
                    text={tour.textInclude ? tour.textInclude : ""}
                    filter={"include"}
                    onChangeText={onChangeText}
                />
                <div className="pd2"/>
                <TourEditText
                    t={t}
                    values={tour.exclude}
                    onChange={onChangeExclude}
                    title={t("generics.excludes") + ":"}
                    text={tour.textExclude ? tour.textExclude : ""}
                    filter={"exclude"}
                    onChangeText={onChangeText}
                />
            </Stack>
        },
        {
            value: "internal",
            element: <TourEditInternals
                t={t}
                tour={tour}
                onChange={onChange}
            />
        },
        {
            value: "info",
            element: <TourEditInfo
                t={t}
                values={tour.info}
                onChange={onChangeInfo}
                text={tour.textInfo ? tour.textInfo : ""}
                filter={"info"}
                onChangeText={onChangeText}
            />
        },
    ]

    const onClickAway = () => {
        if (changed) setDialogUnsavedChanges(true)
    }

    const DialogUnsavedChanges = () => {
        const clickDiscard = () => {
            setChanged(false)
            setDialogUnsavedChanges(false)
            navigate("/mgmt/tour")
        }
        return <Dialog open={openDialogUnsavedChanges}>
            <DialogTitle id="confirm-dialog">
                {t("generics.discardChanges")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-description">
                    {t("generics.discardChangesQuestion")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <MgmtPageSaveClose discard={clickDiscard} onSave={() => onSave(true)} updateSelector={tour.id}/>
            </DialogActions>
        </Dialog>
    }

    return <ResizeBox.Box>
        <MgmtPageTitle
            label={tour.id ?
                t("mgmtTourEdit.edit") + " '" + tour.name + "'" :
                t("mgmtTourEdit.create")}
            icon={"card_travel"}/>
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClickAway}>
            <div role="presentation">
                <div style={{minHeight: ResizeBox.Height({offset: 200})}}>
                    <MgmtPageTabs value={tabsValue} onChange={handleTabsChange} tabs={tabPanels}/>
                </div>
                <MgmtPageSaveClose
                    disabled={!canSave() || !changed}
                    onSave={() => onSave(false)}
                    updateSelector={tour.id}
                    saveClose={() => onSave(true)}
                    redirect={"/mgmt/tour"}/>
            </div>
        </ClickAwayListener>
        <Message message={message} close={() => setMessage({open: false})} open={message.open}/>
        <DialogUnsavedChanges/>
        <SaveProgress/>
    </ResizeBox.Box>
}

export default TourEdit