import ConfigGeneral from "../Config/ConfigGeneral";


//API.GetToken().then((token: string) => console.log("Token" + token))

const API: any = {
    GetToken: async ()  => {
        const login = {
            "login": ConfigGeneral.SYSTEM_LOGIN.login,
            "password": ConfigGeneral.SYSTEM_LOGIN.password
        }
        const res = await fetch(ConfigGeneral.API_URL + '/iam/login', {
            method: "POST",
            headers: {
                'content-type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(login)
        })

        if (!res.ok) {
            return null
        }

        return await res.json().then(data => {
            return data.payload.jwtToken
        })
    },

    RequestPublic: async (url: string, method?: string) => {
        if (!method) method =  "GET"
        const res = await fetch(ConfigGeneral.API_URL + url, {
            method: method,
            headers: {
                'content-type': 'application/json;charset=UTF-8'
            }
        })
        if (!res.ok) {
            return []
        }
        return await res.json().then(data => {
            return data
        })
    },

    Request: async (url: string, method?: string) => {
        if (!method) method =  "GET"
        const res = await fetch(ConfigGeneral.API_URL + url, {
            method: method,
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("st-jwt")
            }
        })
        if (!res.ok) {
            return []
        }
        return await res.json().then(data => {
            return data
        })
    },

    Persist: async (id: number, url: string, body: any) => {
        let method, i: string
        if (id > 0) {
            method = "PUT"
            i = "/" + id
        } else {
            method = "POST"
            i = ""
        }
        const res = await fetch(ConfigGeneral.API_URL + url + i, {
            method: method,
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("st-jwt")
            },
            body: JSON.stringify(body)
        })
        if (res.status >= 400 ) {
            throw res.statusText
        }
        return await res.json().then((data: any) => {
            return data
        })
    },

    Delete: async (url: string) => {
        const res = await fetch(ConfigGeneral.API_URL + url, {
            method: "DELETE",
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("st-jwt")
            }
        })
        if (!res.ok) {
            return null
        }
        return await res.json().then(data => {
            return data
        })
    }
}

export default API