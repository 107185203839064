import React, {useEffect, useState} from "react";
import {Button, Card, CardContent, CardMedia, Divider, Stack, TextField, Typography} from "@mui/material";
import ResizeBox from "../../../Components/ResizeBox";
import {useNavigate, useParams} from "react-router-dom";
import ResponsiveImage from "../../../Components/ResponsiveImage";
import PhotoApi, {PhotoType} from "../../../Api/PhotoApi";
import {useImmer} from "use-immer";
import Message from "../../../Components/Message";
import FullSizeImage from "../../../Components/FullSizeImage";
import ConfigGeneral from "../../../Config/ConfigGeneral";
import ProgressiveImage from "../../../Components/ProgressiveImage";

const PhotoEdit = () => {

    const navigate = useNavigate();
    const params = useParams()

    const [showFullSize, setShowFullSize] = useState(false)
    const [photo, setPhoto] = useImmer<PhotoType>(PhotoApi.DefaultPhoto)
    useEffect(() => {
            if (!photo.id && params.id) {
                PhotoApi.GetPhotoDetail(params.id)
                    .then((data: any) => {
                        const payload = data.payload
                        setPhoto(payload)
                    })
                    .catch((error: string) => {
                        console.log("error: " + error)
                    })
            }
        }, [params.id, photo.id, setPhoto]
    )

    const canSave = () => {
        return !!(photo && photo.name)
    }

    const [changed, setChanged] = useState(false)
    const onChange = (name: string, value: any) => {
        setPhoto((draft) => {
            switch (name) {
                case "name": {
                    draft.name = value
                    break
                }
                case "description": {
                    draft.description = value
                    break
                }
            }
        })
        setChanged(true)
    }

    interface MessageProps {
        severity?: string
        message?: string
        open: boolean
    }

    const emptyMessage: MessageProps = {
        severity: "success",
        message: "I'm ok",
        open: false
    }

    const [message, setMessage] = useState<MessageProps>(emptyMessage)
    const onSave = (close?: boolean) => {
        const delay = (ms: number) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        PhotoApi.PersistPhoto(photo).then(() => {
            if (close) {
                delay(500).then(() => {
                    setPhoto(PhotoApi.DefaultPhoto)
                    navigate("/mgmt/photo")
                })
            } else {
                setMessage({
                    severity: "success",
                    message: `Changes for tour "${photo.name}" saved successfully `,
                    open: true
                })
                setChanged(false)
            }
        }).catch((error: string) => {
            setMessage({
                severity: "error",
                message: `Error saving changes ${error}`,
                open: true
            })

        })
    }

    const RenderInfo = ({title, info}: any) => {
        return <Typography variant="caption">
            <strong>{title}:</strong> {info}
        </Typography>
    }

    return <ResizeBox.Box>
        <Stack direction="row" alignItems="center" gap={1}>
            {photo && photo.thumb ?
                <ResponsiveImage src={photo.thumb} circle sx={{height: "38px", width: "38px"}}/>
                : ""
            }
            <Typography variant='h5'>Edit Photo</Typography>
        </Stack>
        <div className='pd1'/>
        <Stack direction="row" gap={3} sx={{minHeight: ResizeBox.Height({offset: 200})}}>
            <Stack gap={1} flex={1}>
                <TextField
                    autoFocus
                    required
                    fullWidth
                    value={photo && photo.name ? photo.name : ""}
                    margin="dense"
                    id="name"
                    name="name"
                    label="Photo Name"
                    helperText="Original Name of photo, just used as alternate text"
                    type="text"
                    variant="standard"
                    onChange={(element) => onChange(element.target.name, element.target.value)}
                />
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={photo && photo.description ? photo.description : ""}
                    margin="dense"
                    id="description"
                    name="description"
                    helperText="Addional description"
                    label="Description"
                    type="text"
                    variant="filled"
                    onChange={(element) => onChange(element.target.name, element.target.value)}
                />
            </Stack>
            <Stack flex={0.5}>
                <Card>
                    {photo && photo.link ?
                        <CardMedia>
                            <ProgressiveImage
                                src={photo.thumb}
                                placeholder={ConfigGeneral.URL_DUMMY_PHOTO}
                                style={{width: "100%"}}
                                onClick={() => setShowFullSize(true)}
                            />
                        </CardMedia>
                        : ""
                    }
                    <CardContent>
                        <Stack>
                            <RenderInfo title="Size" info={Math.round(photo.size / 1024) + " KB"}/>
                            <RenderInfo title="Variant" info={photo.variant}/>
                            <RenderInfo title="Media Type" info={photo.mediaType}/>
                            <RenderInfo title="ObjectID" info={photo.objectID}/>
                            <RenderInfo title="Storage" info={photo.store.type}/>
                            <RenderInfo title="Link" info={photo.link}/>
                            <RenderInfo title="Thumb" info={photo.thumb}/>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Stack>
        <Divider sx={{mt: 1}}/>
        <Stack direction="row-reverse" sx={{paddingBottom: 4}}>
            <Button onClick={() => onSave(true)} disabled={!canSave() || !changed}>
                Update and Close
            </Button>
            <Button onClick={() => onSave(false)} disabled={!canSave() || !changed}>
                Update
            </Button>
            <Button onClick={() => navigate("/mgmt/photo")}>
                Cancel
            </Button>
        </Stack>
        <FullSizeImage src={photo.link} name={photo.name} open={showFullSize} close={() => setShowFullSize(false)}/>
        <Message message={message} close={() => setMessage({open: false})} open={message.open}/>
    </ResizeBox.Box>
}

export default PhotoEdit