import React, {useEffect, useState} from "react"
import TourApi from "../../Api/TourApi";
import {useNavigate} from "react-router-dom";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Fade,
    Grid,
    Stack,
    Typography,
    useScrollTrigger
} from "@mui/material";
import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";

const CardTourGroup = () => {
    const navigate = useNavigate()
    const [tourGroups, setTourGroups] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading)
            TourApi.GetPublicGroup().then((d: any) => {
                setTourGroups(d.payload)
                setLoading(false)
            })
    }, [tourGroups, loading])

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 370,
    });

    return <Fade in={trigger} timeout={4000}>
        <Grid container spacing={{xs: 1, lg: 2}} columns={{xs: 1, sm: 2, md: 3, lg: 3}}>
            {tourGroups ? tourGroups.map((group: any, index: number) => {
                return <Grid item xs={1} key={group.id}>
                    <Card key={index} sx={{background: "rgb(102,102,102,1)", color: "white"}}>
                        <CardActionArea onClick={() => navigate(group.route.route)}>
                            {group.cardPhoto.link ?
                                <CardMedia
                                    sx={{height: {xs: "220px", sm: "240px", md: "240px", lg: "280px"}}}
                                    component="img"
                                    alt={group.cardPhoto.name}
                                    image={group.cardPhoto.link}
                                /> : ""}
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    noWrap
                                    sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontVariant: "small-caps",
                                    }}
                                >
                                    {group.name}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="white"
                                    noWrap
                                    sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {group.subtitle}
                                </Typography>
                                <Stack direction="row" alignItems="center" alignContent="center">
                                    <CardTravelOutlinedIcon sx={{fontSize: "small", color: "lightgray", mr: 1}}/>
                                    <Typography
                                        variant="subtitle2"
                                        color="lightgray"
                                        sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {group.tour ? group.tour.length + " Reisen gefunden" :""}
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            }) : ""}
        </Grid>
    </Fade>
}

export default CardTourGroup