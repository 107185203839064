import React from "react";
import {Outlet} from "react-router-dom";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

const AppSkeleton = () => {
    return <React.Fragment>
            <AppHeader/>
            <Outlet/>
            <AppFooter/>
        </React.Fragment>
}

export default AppSkeleton