import React from "react"
import {Box, Checkbox, FormControlLabel, InputAdornment, Stack, TextField} from "@mui/material";
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import SelectSeason from "./Components/SelectSeason";
import SelectCountry from "./Components/SelectCountry";
import SelectLanguage from "./Components/SelectLanguage";
import SelectTag from "./Components/SelectTag";
import {TFunction} from "i18next";
import InputPrice from "./Components/InputPrice";

interface Props {
    t: TFunction
    tour: any
    onChange: any
    onSetActive: any
    onChangeSeason: any
}

const TourEditOptions = (props: Props) => {
    const t = props.t
    const {tour, onChange, onSetActive, onChangeSeason} = props

    return <Box
        component="form"
        noValidate
        autoComplete="off"
    >
        <Stack direction="row" gap={3}>
            <InputPrice
                t={t}
                required={true}
                value={tour.startPrice}
                onChange={onChange}
                label={"mgmtTourEdit.startPrice"}
                helperText={"mgmtTourEdit.helperStartPrice"}/>
            <TextField
                required
                sx={{
                    width: '17ch'
                }}
                margin="dense"
                id="nights"
                name="nights"
                label={t("mgmtTourEdit.nights")}
                helperText={t("mgmtTourEdit.helperNights")}
                type="text"
                variant="standard"
                value={tour.nights ? tour.nights : ""}
                onChange={(event) => onChange("nights", event.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><BedOutlinedIcon/></InputAdornment>,
                }}
            />
            <TextField
                sx={{
                    width: '40ch'
                }}
                margin="dense"
                id="accommodation"
                name="accommodation"
                label={t("mgmtTourEdit.accommodation")}
                helperText={t("mgmtTourEdit.accommodation")}
                type="text"
                variant="standard"
                value={tour.accommodation ? tour.accommodation : ""}
                onChange={(event) => onChange("accommodation", event.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><HotelOutlinedIcon/></InputAdornment>,
                }}
            />
        </Stack>
        <Stack direction="row" gap={3}>
            <TextField
                required
                sx={{
                    width: '40ch'
                }}
                margin="dense"
                id="startPlace"
                name="startPlace"
                label={t("mgmtTourEdit.startPlace")}
                helperText={t("mgmtTourEdit.helperStartPlace")}
                type="text"
                variant="standard"
                value={tour.startPlace ? tour.startPlace : ""}
                onChange={(event) => onChange("startPlace", event.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><FlightTakeoffIcon/></InputAdornment>,
                }}
            />
            <TextField
                autoFocus
                required
                sx={{
                    width: '40ch'
                }}
                margin="dense"
                id="finishPlace"
                name="finishPlace"
                label={t("mgmtTourEdit.finishPlace")}
                helperText={t("mgmtTourEdit.helperFinishPlace")}
                type="text"
                variant="standard"
                value={tour.finishPlace ? tour.finishPlace : ""}
                onChange={(event) => onChange("finishPlace", event.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><FlightLandIcon/></InputAdornment>,
                }}
            />
        </Stack>
        <Stack direction="row" gap={1} mt={3}>
            <TextField
                id="character"
                label={t("mgmtTourEdit.character")}
                variant="filled"
                multiline
                value={tour.character ? tour.character : ""}
                placeholder="Character of the tour"
                name="character"
                fullWidth
                rows={4}
                onChange={(event) => onChange("character", event.target.value)}
            />
        </Stack>
        <Stack direction="row" gap={1} mt={3}>
            <TextField
                autoFocus
                required
                fullWidth
                margin="dense"
                id="guidedBy"
                name="guidedBy"
                label={t("mgmtTourEdit.guide")}
                helperText={t("mgmtTourEdit.helperGuide")}
                type="text"
                variant="standard"
                value={tour.guidedBy ? tour.guidedBy : ""}
                onChange={(event) => onChange("guidedBy", event.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><FollowTheSignsIcon/></InputAdornment>,
                }}
            />
        </Stack>
        <Stack direction="row" gap={1}>
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        checked={tour.season.yearAround}
                        onChange={(_, d) => onSetActive("yearAround", d)}
                    />
                }
                sx={{mt: '14px', width: "20ch"}}
                label={t("mgmtTourEdit.allYearAround")}
            />
            <SelectSeason t={t} onChange={onChangeSeason} value={tour.season}/>
        </Stack>
        <Stack direction="row" gap={1} mt={3}>
            <SelectCountry t={t} onChange={onChange} value={tour.country}/>
        </Stack>
        <Stack direction="row" gap={1} mt={3}>
            <SelectLanguage t={t} onChange={onChange} value={tour.language}/>
        </Stack>
        <Stack direction="row" gap={1} mt={3}>
            <SelectTag onChange={onChange} value={tour.tag}/>
        </Stack>
    </Box>
}

export default TourEditOptions
