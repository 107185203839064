import {InputAdornment, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {TFunction} from "i18next";

interface Props {
    t: TFunction
    value: any
    onChange: any
    label: string
    helperText?: string
    required?: boolean
}

const InputPrice = (props: Props) => {
    const {t, value, onChange, label, helperText, required} = props
    const [number, setNumber] = useState('')
    const [loading, setLoading] = useState(true)
    const formatPrice = (price: number) => {
        return price && price > 0 ? String(price.toFixed(2)).replace(".", ",") : ""
    }

    useEffect(() => {
        if (loading && value) {
            setNumber(formatPrice(value))
            setLoading(false)
        }
    }, [loading, value])

    const handleNumber = (e: any) => {
        if (e.target.value.match(/^([0-9]+)?(,)?([0-9]+)?$/)) {
            setNumber(e.target.value)
        }
    }

    const handleChange = (e: any) => {
        if (e.target.value !== formatPrice(value)) {
            onChange("startPrice", parseFloat(e.target.value.replace(",", ".")))
        }
    }

    return <TextField
        required={required}
        sx={{width: '20ch'}}
        margin="dense"
        id="startPrice"
        name="startPrice"
        label={t(label)}
        helperText={helperText ? t(helperText) : ""}
        type="text"
        variant="standard"
        value={number}
        onBlur={handleChange}
        onChange={handleNumber}
        InputProps={{startAdornment: <InputAdornment position="start">€</InputAdornment>}}
    />
}

export default InputPrice