import React, {useEffect, useState} from "react"
import {useImmer} from "use-immer";
import {useNavigate, useParams} from "react-router-dom";
import TourApi from "../../../Api/TourApi";
import ResizeBox from "../../../Components/ResizeBox";
import {Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography, Button} from "@mui/material";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";

interface TextBlock {
    id: number | null
    type: string | null
    name: string | null
    text: string | null
}

const TextModulesEdit = () => {

    const textBlock: TextBlock = {
        id: null,
        type: null,
        name: null,
        text: null
    }

    const [value, setValue] = useImmer<TextBlock>(textBlock)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const params = useParams()

    useEffect(() => {
        if (loading && params.id) {
            TourApi.GetTextDetail(params.id).then((data: any) => {
                setValue(data.payload)
            })
            setLoading(false)
        }
    }, [loading, params.id, setValue])

    const options =
        [
            {text: "Universal", value: "universal", key: 0},
            {text: "Date", value: "date", key: 1},
            {text: "Include", value: "include", key: 2},
            {text: "Exclude", value: "exclude", key: 3},
            {text: "Individual", value: "individual", key: 4},
            {text: "Program", value: "program", key: 5},
        ]

    const onChange = (key: string, value: any) => {
        setValue((draft) => {
            switch (key) {
                case "type": {
                    draft.type = value
                    break
                }
                case "text": {
                    draft.text = value
                    break
                }
                case "name": {
                    draft.name = value
                    break
                }
            }
        })
    }

    const onSave = () => {
        TourApi.PersistText(value).then(() => {
            navigate("/mgmt/textModules")
        }).catch((error: string) => {
            console.log("TextModuleEdit, onSave: " + error)
        })
    }

    const canSave = () => {
        if (value.name &&
            value.text &&
            value.type) return true
        return false
    }

    const onDelete = () => {
        TourApi.DeleteText(value.id).then(() => {
            navigate("/mgmt/textModules")
        }).catch((error: string) => {
            console.log("TextModuleEdit, onDelete: " + error)
        })

    }

    return <ResizeBox.Box>
        <Box style={{minHeight: ResizeBox.Height, width: "100%"}}>
            <Stack direction="row" alignItems="center" gap={1}>
                <TextSnippetOutlinedIcon fontSize='medium'/>
                <Typography variant='h5'>Edit Text Modules</Typography>
            </Stack>
            <div className='pd1'/>
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <TextField
                    autoFocus
                    required
                    value={value && value.name ? value.name : ""}
                    margin="dense"
                    id="name"
                    name="name"
                    label="Eindeutiger Name für den Textbaustein"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e: any) => onChange("name", e.target.value)}
                />
                <FormControl sx={{mt: 2}} variant="standard">
                    <InputLabel id="label-type">Type</InputLabel>
                    <Select
                        labelId="label-role"
                        id="type"
                        value={value && value.type ? value.type : ""}
                        label="Type"
                        onChange={(e: any) => onChange("type", e.target.value)}
                        sx={{width:"40ch"}}
                    >
                        {options.map((r: any, index: number) => {
                            return <MenuItem key={index} value={r.value}>{r.text}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <TextField
                    id="text"
                    label="Text Module"
                    multiline
                    variant="filled"
                    value={value && value.text ? value.text : ""}
                    placeholder='Text Module'
                    name="text"
                    fullWidth
                    sx={{mt:4}}
                    rows={5}
                    onChange={(e) => onChange("text", e.target.value)}
                />
                <Stack direction="row-reverse">
                    <Button onClick={onSave} disabled={!canSave()}>
                        {value.id ? "Update" : "Create"}
                    </Button>
                    <Button
                        onClick={() => navigate("/mgmt/textModules")}>
                        Cancel
                    </Button>
                    <Button
                        onClick={onDelete} color="error">
                        Delete
                    </Button>
                </Stack>
            </Box>
        </Box>
    </ResizeBox.Box>

}

export default TextModulesEdit