const REACT_APP_ENV = process.env.REACT_APP_ENV
const VERSION = "0.0.0"
const BE_DEV = "http://localhost:7080/api"
const BE_TEST = "https://silvertip.tours/api"
const BE_PROD = "https://silvertip.at/api"

// Set the backend to match the environment
const API_ROOT = REACT_APP_ENV === "PROD" ?
    BE_PROD
    : REACT_APP_ENV === "TEST" ?
        BE_TEST
        : BE_DEV

const ConfigGeneral = {
    API_URL: API_ROOT,
    VERSION: VERSION,
    SYSTEM_LOGIN: {
        "login": process.env.REACT_APP_SYSTEM_LOGIN,
        "password": process.env.REACT_APP_SYSTEM_PASSWORD
    },
    URL_PUBLIC_PHOTO: "https://storage.googleapis.com/"+process.env.REACT_APP_S3,
    URL_DUMMY_PHOTO: API_ROOT + "/images/dummy_image.png"
}

export default ConfigGeneral