import {Button, Divider, Stack} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Cancel, Check} from "@mui/icons-material";

interface Props {
    redirect?: string
    disabled?: boolean
    saveClose?: any
    onSave: any
    updateSelector: any
    discard?: any
}

const MgmtPageSaveClose = (props: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return <>
        <Divider sx={{mt: 1}}/>
        <Stack direction={"row"} style={{paddingBottom: 4, float: "right"}}>
            {props.saveClose ?
                <Button onClick={props.saveClose} disabled={props.disabled} startIcon={<Check/>}>
                    {props.updateSelector ? t("generics.updateClose") : t("generics.createClose")}
                </Button> :
                ''}
            <Button onClick={props.onSave} disabled={props.disabled} startIcon={<Check/>}>
                {props.updateSelector ? t("generics.update") : t("generics.create")}
            </Button>
            {props.redirect ?
                <Button onClick={() => navigate(props.redirect ? props.redirect : '')} color={"error"}
                        startIcon={<Cancel/>}>
                    {t("generics.cancel")}
                </Button> : ''}
            {props.discard ?
                <Button onClick={props.discard} color={"error"} startIcon={<Cancel/>}>
                    {t("generics.discard")}
                </Button> : ''}
        </Stack>
    </>
}

export default MgmtPageSaveClose;