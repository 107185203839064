import React, {useEffect, useState} from "react";
import {
    IconButton,
    Stack,
} from "@mui/material";
import ResizeBox from "../../../Components/ResizeBox";
import PhotoApi from "../../../Api/PhotoApi";
import {DataGrid, GridColDef, GridRowSelectionModel} from "@mui/x-data-grid";
import ResponsiveImage from "../../../Components/ResponsiveImage";
import {useNavigate} from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Message from "../../../Components/Message";
import DialogPhotoSet from "./DialogPhotoSet";
import MgmtPageTitle from "../Components/MgmtPageTitle";
import MgmtPageActions from "../Components/MgmtPageActions";

const PhotoOverview = () => {
    const navigate = useNavigate();
    const [photos, setPhotos] = useState([])
    useEffect(() => {
        PhotoApi.GetPhotos().then((data: any) => {
            if (data) {
                setPhotos(data.payload.map((p: any) => {
                    return {...p, actionID: p.id}
                }));
            }
        })
    }, []);

    const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([])
    const onRowClick = (id: any) => {
        setRowSelection(id)
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            align: "center",
            headerAlign: "center",
            headerClassName: 'tableHeader',
        },
        {
            field: "thumb",
            headerName: "Image",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return <ResponsiveImage src={params.value} sx={{height: "50px"}}/>
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5,
            headerClassName: 'tableHeader',
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            headerClassName: 'tableHeader',
        },
        {
            field: 'variant',
            headerName: 'Variant',
            headerClassName: 'tableHeader',
        },
        {
            field: 'objectID',
            headerName: 'ObjectID',
            headerClassName: 'tableHeader',
        },
        {
            field: 'size',
            headerName: 'Size',
            headerClassName: 'tableHeader',
            renderCell: (params) => {
                return Math.round(params.value / 1024) + " KB"
            }
        },
        {
            field: "actionID",
            headerName: "",
            headerClassName: 'tableHeader',
            sortable: false,
            filterable: false,
            align: "right",
            headerAlign: "right",
            width: 5,
            renderCell: (params) => {
                return <Stack direction="row" gap={0} p={0}>
                    <IconButton sx={{m: 0}} onClick={() => {
                        navigate("/mgmt/photo/edit/" + params.value, {replace: true})
                    }}>
                        <EditOutlinedIcon color="action" fontSize="small"/>
                    </IconButton>
                </Stack>
            }
        },
    ]

    interface MessageProps {
        severity?: string
        message?: string
        open: boolean
    }

    const emptyMessage: MessageProps = {
        severity: "success",
        message: "I'm ok",
        open: false
    }

    const [message, setMessage] = useState<MessageProps>(emptyMessage)
    const [showDialogPhotoSet, setShowDialogPhotoSet] = useState(false)
    const actions = [
        { label: "photo.add", onClick: ()=>navigate("/mgmt/photo/add", {replace: true})},
        { label: "photo.createPhotoSet", onClick: () => setShowDialogPhotoSet(true), disabled:rowSelection.length === 0}
    ]
    return <ResizeBox.Box>
        <MgmtPageTitle label={"photo.photos"} icon={"photo_library"}/>
        <MgmtPageActions actions={actions}/>
        <DataGrid
            sx={{
                height: ResizeBox.Height,
                ".MuiDataGrid-columnHeaderTitleContainer": {
                    backgroundColor: "aliceblue",
                },
            }}
            autoPageSize
            rows={photos}
            columns={columns}
            checkboxSelection
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
            onRowSelectionModelChange={(i: any) => {
                onRowClick(i)
            }}
            rowSelectionModel={rowSelection}
            rowHeight={39}
            columnHeaderHeight={39}
        />
        <DialogPhotoSet
            selected={rowSelection}
            open={showDialogPhotoSet}
            close={setShowDialogPhotoSet}
            photos={photos}
            setMessage={setMessage}/>
        <Message message={message} close={() => setMessage({open: false})} open={message.open}/>
    </ResizeBox.Box>
}

export default PhotoOverview