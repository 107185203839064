import React from "react";
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';

import './App.css';
import Home from "./View/Home";
import Management from "./View/Management/Management";
import ManagementSkeleton from "./View/Management/ManagementSkeleton";
import AppSkeleton from "./AppSkeleton";
import User from "./View/Management/IAM/User";
import TourGroupOverview from "./View/Management/Tour/Group/TourGroupOverview";
import Info from "./View/Info";
import GroupEdit from "./View/Management/Tour/Group/TourGroupEdit";
import AppRender from "./AppRender";
import TourOverview from "./View/Management/Tour/Tour/TourOverview";
import TourEdit from "./View/Management/Tour/Tour/TourEdit";
import TextModules from "./View/Management/TextModules/TextModules";
import TextModulesEdit from "./View/Management/TextModules/TextModulesEdit";
import PhotoOverview from "./View/Management/Photo/PhotoOverview";
import PhotoEdit from "./View/Management/Photo/PhotoEdit";
import PhotoSetEdit from "./View/Management/Photo/PhotoSetEdit";
import PhotoSet from "./View/Management/Photo/PhotoSet";
import PhotoAdd from "./View/Management/Photo/PhotoAdd";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<AppSkeleton/>}>
                    <Route
                        path="/info"
                        element={<Info/>}
                        errorElement={<Home/>}
                    />
                    <Route
                        path="/"
                        element={<Home/>}
                        errorElement={<Navigate to="/"/>}
                    />
                </Route>
                <Route element={<ManagementSkeleton/>}>
                    <Route
                        path="/mgmt"
                        element={<Management/>}
                        errorElement={<Home/>}
                    />
                    <Route
                        path="/mgmt/user"
                        element={<User/>}
                        errorElement={<Management/>}
                    />
                    <Route
                        path="/mgmt/tour"
                        element={<TourOverview/>}
                        errorElement={<Management/>}
                    />
                    <Route
                        path="/mgmt/tour/edit"
                        element={<TourEdit/>}
                        errorElement={<TourOverview/>}
                    />
                    <Route
                        path="/mgmt/tour/edit/:id"
                        element={<TourEdit/>}
                        errorElement={<TourOverview/>}
                    />
                    <Route
                        path="/mgmt/tour/group/edit"
                        element={<GroupEdit/>}
                        errorElement={<TourGroupOverview/>}
                    />
                    <Route
                        path="/mgmt/tour/group/edit/:id"
                        element={<GroupEdit/>}
                        errorElement={<TourGroupOverview/>}
                    />
                    <Route
                        path="/mgmt/tour/group"
                        element={<TourGroupOverview/>}
                        errorElement={<Management/>}
                    />
                    <Route
                        path="/mgmt/textModules"
                        element={<TextModules/>}
                        errorElement={<Management/>}
                    />
                    <Route
                        path="/mgmt/textModules/edit"
                        element={<TextModulesEdit/>}
                        errorElement={<Management/>}
                    />
                    <Route
                        path="/mgmt/textModules/edit/:id"
                        element={<TextModulesEdit/>}
                        errorElement={<Management/>}
                    />
                    <Route
                        path="/mgmt/photo"
                        element={<PhotoOverview/>}
                        errorElement={<Management/>}
                    />
                    <Route
                        path="/mgmt/photo/add"
                        element={<PhotoAdd/>}
                        errorElement={<Management/>}
                    />
                    <Route
                        path="/mgmt/photo/edit/:id"
                        element={<PhotoEdit/>}
                        errorElement={<Management/>}
                    />
                    <Route
                        path="/mgmt/photoSet"
                        element={<PhotoSet/>}
                        errorElement={<Management/>}
                    />
                    <Route
                        path="/mgmt/photoSet/edit/:id"
                        element={<PhotoSetEdit/>}
                        errorElement={<Management/>}
                    />
                </Route>
                <Route
                    path="*"
                    element={<AppRender/>}
                />
            </Routes>
        </BrowserRouter>
    )
}


export default App;
